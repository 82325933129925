import React, { useEffect, useState } from "react";
import { FileExcel } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable from "src/modules/common/components/DataTable";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import SearchSelect from "src/modules/common/components/SearchSelect";
import { commonActions } from "src/modules/common/handler/redux";
import { DownloadReport } from "src/modules/common/helpers/helpers";
import useAreas from "src/modules/evaluacion/hooks/useAreas";
import useDepartamentos from "src/modules/evaluacion/hooks/useDepartamentos";
import useSucursales from "src/modules/evaluacion/hooks/useSucursales";
import { getToken } from "src/utils/helpers";
import { SearchInput } from "../components/SearchInput";
import { permisoActions } from "../handlers/redux";
import { getStatusColors } from "../helpers/constants";

export function PermisoHistorial() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Estados para filtros y ordenamiento
  const [historialFilter, setHistorialFilter] = useState({});
  //
  const [searchHistorial, setSearchHistorial] = useState("");

  // Estados globales desde Redux
  const {
    solicitudes_historial,
    solicitudes_historial_loading,
    solicitudes_historial_filter,
    solicitudes_historial_count,
    pendientes_count,
  } = useSelector((state) => state.permiso);

  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const [descargarReporteLoading, setDescargarReporteLoading] = useState(false);

  const {
    sucursales,
    nombre: nombreSucursal,
    filterSucursales,
  } = useSucursales();
  const { areas, nombre: nombreArea, filterAreas } = useAreas();
  const {
    departamentos,
    nombre: nombreDepartamento,
    filterDepartamentos,
  } = useDepartamentos();

  useEffect(() => {
    dispatch(commonActions.setTitle("Permisos"));
    dispatch(
      commonActions.setTitleMenu([
        {
          name: "Solicitudes",
          to: "/permisos",
          additionalTitle: pendientes_count,
        },
        {
          name: "Historial",
          to: "/permisos/historial",
        },
      ])
    );
    return () => {
      commonActions.setTitleMenu([]);
    };
  }, [pendientes_count]);

  useEffect(() => {
    dispatch(
      permisoActions.getSolicitudesHistorial({
        ...historialFilter,
        filter: { search: searchHistorial },
      })
    );
  }, [dispatch, historialFilter, searchHistorial]);

  const [reporteFilters, setReporteFilters] = useState({});

  const handleDownloadReport = () => {
    // const token = localStorage.getItem("authToken"); // Adjust how you retrieve the token

    DownloadReport({
      token: getToken(),
      url: `/permiso/exportar_historial/`,
      method: "POST",
      type: "xlsx",
      name: "permisos_report",
      data: reporteFilters, // Passing filters as request data
      loadingSetter: setDescargarReporteLoading,
    });
  };

  const datatableConfigPermisosPendientes = {
    headers: [
      {
        title: "Solicitante",
        contentClass: "font-bold",
        // onOrder: setOrderingPermisos("empleado"),
        accessor: "solicitante",
        contentRenderer: (item) => {
          const { solicitante, cargo, solicitante_foto } = item;
          return (
            <div className="flex items-center gap-3">
              <div>
                {!solicitante_foto && (
                  <ProfileImage className="h-28 w-28 p-3"></ProfileImage>
                )}
                {!!solicitante_foto && (
                  <div className="w-16 bg-slate-200 rounded-md">
                    <img
                      className="object-cover h-16 w-16 rounded-md"
                      src={solicitante_foto}
                      alt={`Foto ${solicitante}`}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <div className="font-semibold">{solicitante}</div>
                <div>Cargo: {cargo ? cargo : "Sin cargo"}</div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Tipo",
        accessor: "tipo",
        // onOrder: setOrderingPermisos("tipo_permiso"),
      },
      {
        title: "Asunto",
        accessor: "asunto",
        // onOrder: setOrderingPermisos("estado"),
      },
      {
        title: "Tiempo",
        accessor: "dias",
        // onOrder: setOrderingPermisos("estado"),
        contentRenderer: (item) => {
          return (
            <div>
              {item.dias} {item.dias > 1 ? "días" : "día"}
            </div>
          );
        },
      },
      {
        title: "Estado",
        accessor: "estado",
        // onOrder: setOrderingPermisos("estado"),
        contentRenderer: (item) => {
          return (
            <div
              style={{
                backgroundColor: getStatusColors(item.estado).background,
              }}
              className={`flex flex-col text-center rounded-md py-1 w-full`}
            >
              <span
                style={{ color: getStatusColors(item.estado).text }}
                className="capitalize text-sm"
              >
                {item.estado}
              </span>
            </div>
          );
        },
      },
    ],
    rowActions: [
      // {
      //   label: "Aprobar",
      //   action: (item) => {
      //     dispatch(permisoActions.aceptarPermiso(item.id));
      //   },
      // },
      // {
      //   label: "Rechazar",
      //   action: (item) => {
      //     dispatch(permisoActions.rechazarPermiso(item.id));
      //   },
      // },
    ],
  };

  const onPaginatePermisos = (page, pageSize) => {
    dispatch(
      permisoActions.getSolicitudesHistorial({
        ...solicitudes_historial_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const goToPermisoDetalle = (permiso) => {
    navigate(`/permisos/detalle/${permiso.id}`);
  };

  return (
    <div>
      <div className="flex flex-col gap-4 px-12 py-4">
        <>
          {/* Tabla del historial de solicitudes procesadas */}
          <div>
            <h1 className="font-semibold text-lg mb-4">
              Historial de permisos
            </h1>

            <div className="mb-4 w-full flex items-center justify-between gap-4">
              <div className="flex items-center flex-1 gap-4">
                <SearchInput
                  value={searchHistorial}
                  onChange={setSearchHistorial}
                  className="w-full md:w-1/2"
                />
                <MostrarPaginas
                  containerClass={"w-1/2x"}
                  defaultOption={10}
                  onChangePageSize={(e) => setPageSize(e.target.value)}
                />
              </div>
              <div>
                <button
                  disabled={descargarReporteLoading}
                  className="bg-green-600 text-white font-semibold rounded-md px-4 py-2 flex items-center"
                  onClick={handleDownloadReport}
                >
                  <FileExcel className="mr-2" size={22} />
                  {descargarReporteLoading ? "Descargando..." : "Exportar XLS"}
                </button>
              </div>
            </div>
            <div className="flex gap-4 my-8">
              <SearchSelect
                data={sucursales}
                defaultText={"Sucursales: Todos"}
                searchValue={nombreSucursal}
                filterFunction={filterSucursales}
                onSelectItem={(item) => {
                  setReporteFilters((prevState) => ({
                    ...prevState,
                    sucursal: item,
                  }));
                  dispatch(
                    permisoActions.getSolicitudesHistorial({
                      ...historialFilter,
                      sucursal: item,
                    })
                  );
                }}
              />
              <SearchSelect
                data={areas}
                defaultText={"Áreas: Todas"}
                searchValue={nombreArea}
                filterFunction={filterAreas}
                onSelectItem={(item) => {
                  setReporteFilters((prevState) => ({
                    ...prevState,
                    area: item,
                  }));
                  dispatch(
                    permisoActions.getSolicitudesHistorial({
                      ...historialFilter,
                      area: item,
                    })
                  );
                }}
              />
              <SearchSelect
                data={departamentos}
                defaultText={"Departamentos: Todos"}
                searchValue={nombreDepartamento}
                filterFunction={filterDepartamentos}
                onSelectItem={(item) => {
                  setReporteFilters((prevState) => ({
                    ...prevState,
                    departamento: item,
                  }));
                  dispatch(
                    permisoActions.getSolicitudesHistorial({
                      ...historialFilter,
                      departamento: item,
                    })
                  );
                }}
              />
            </div>
            {/* Componente de búsqueda */}
            {solicitudes_historial_loading ? (
              <LoadingSpinButton message="Cargando..." />
            ) : (
              <div>
                <DataTable
                  containerClass={"my-8"}
                  data={solicitudes_historial}
                  loading={solicitudes_historial_loading}
                  config={datatableConfigPermisosPendientes}
                  // Pagination
                  totalRecords={solicitudes_historial_count}
                  onPaginate={onPaginatePermisos}
                  outerPageSize={PageSize}
                  outerPage={Page}
                  outerSetPage={setPage}
                  // Check functionality (left as false since you mentioned you don’t need it now)
                  checkable={false}
                  onRowClick={goToPermisoDetalle}
                />
              </div>
            )}
          </div>
        </>
      </div>
    </div>
  );
}
