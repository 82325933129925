import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Header2 } from "src/modules/common/components/Header2";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import backButton from "src/modules/common/components/images/back-arrow.png";
import { commonActions } from "src/modules/common/handler/redux";
import ArchivoLinks from "../components/ArchivoLinks";
import { permisoActions } from "../handlers/redux";

export function PermisoDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams(); // Get the permiso ID from the URL parameters

  // Select the permiso detail from the Redux store
  const permiso = useSelector((state) => state.permiso.permiso);
  const loading = useSelector((state) => state.permiso.permiso_loading);
  const error = useSelector((state) => state.permiso.permiso_error);

  const comentario = useSelector((state) => state.permiso.comentario);
  const comentario_loading = useSelector(
    (state) => state.permiso.comentario_loading
  );
  const comentario_error = useSelector(
    (state) => state.permiso.comentario_error
  );

  const handleAddComment = () => {
    if (comentario.trim()) {
      dispatch(
        permisoActions.addComentario({ permisoId: id, comentario: comentario })
      );
    }
  };

  useEffect(() => {
    dispatch(commonActions.setTitle("Detalle de Permiso"));
    dispatch(commonActions.setTitleMenu([]));
    return () => {
      commonActions.setTitleMenu([]);
    };
  }, []);

  useEffect(() => {
    // Dispatch the action to get the permiso details
    dispatch(permisoActions.getPermiso(id));
  }, [dispatch, id]);

  // Handle loading and error states
  if (loading) {
    return <div className="text-center">Cargando...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">Error: {error}</div>;
  }

  // Handler for accepting the permiso
  const handleAccept = () => {
    const confirm = window.confirm("Está seguro de ACEPTAR la solicitud?");
    if (confirm) dispatch(permisoActions.aceptarPermiso(id));
  };

  // Handler for rejecting the permiso
  const handleReject = () => {
    const confirm = window.confirm("Está seguro de RECHAZAR la solicitud?");
    if (confirm) dispatch(permisoActions.rechazarPermiso(id));
  };

  const onBack = () => {
    return (
      <div
        onClick={() => navigate(-1)}
        className={"h-full flex items-center pr-2 z-10 cursor-pointer mr-2"}
      >
        <img className="back" src={backButton} alt="Regresar" />
      </div>
    );
  };

  return (
    <>
      <Header2
        titulo={"Volver"}
        className={`bg-slate-100 -mx-8 px-6`}
        LeftComponent={onBack}
      />
      <div className="containerx px-12 py-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Left Column: Permiso Information */}
          <div className="p-4">
            <div className="flex justify-between pb-2 border-b mb-4">
              <h2 className="text-lg font-semibold ">{permiso.tipo}</h2>
              {permiso.es_editable && (
                <button
                  onClick={() => navigate(`/permisos/form/${id}`)}
                  className="bg-sky-500 text-white rounded-md py-2 px-4"
                >
                  Editar
                </button>
              )}
            </div>
            <div className="flex flex-col gap-3 mb-3">
              <strong>Asunto:</strong>
              <div>{permiso.asunto}</div>
            </div>
            <div className="flex flex-col gap-3 mb-3">
              <strong>Descripción:</strong>
              <div>{permiso.descripcion}</div>
            </div>
            <div className="flex flex-col gap-3 mb-3">
              <strong>Tiempo de permiso:</strong>
              <div>
                {dayjs(permiso.inicio?.slice(0, 10)).format("DD/MM/YYYY")}{" "}
                {permiso.hora_inicio?.slice(0, 5)} -{" "}
                {dayjs(permiso.fin?.slice(0, 10)).format("DD/MM/YYYY")}{" "}
                {permiso.hora_fin?.slice(0, 5)} ({permiso.dias}{" "}
                {permiso.dias > 1 ? "días" : "día"})
              </div>
            </div>

            {/* Aceptar y Rechazar buttons */}
            {permiso.es_procesable && (
              <div className="mt-4 flex space-x-4">
                <button
                  onClick={handleReject}
                  className="border-black rounded-md p-2"
                >
                  Rechazar
                </button>
                <button
                  onClick={handleAccept}
                  className="bg-sky-500 p-2 text-white rounded-md"
                >
                  Aceptar
                </button>
              </div>
            )}

            {permiso.archivos?.length > 0 && (
              <div className="flex flex-col gap-3 mb-3 mt-3">
                <strong>Archivos adjuntos:</strong>
                <ArchivoLinks archivos={permiso.archivos} />
              </div>
            )}

            {/* Display comentarios */}
            <h3 className="text-lg font-semibold mt-8 mb-4 pb-2 border-b">
              Comentarios
            </h3>
            <div className="flex flex-col gap-2 w-full max-h-96 overflow-auto custom-scrollbar">
              {permiso.comentarios?.map((comentario) => (
                <div
                  key={`comen-${comentario.id}`}
                  className="flex items-centerx gap-2 w-full"
                >
                  <div className="mt-2">
                    {comentario.foto_comentador ? (
                      <img
                        src={comentario.foto_comentador}
                        alt={comentario.comentador}
                        className="w-16 h-16 rounded-md mr-4"
                      />
                    ) : (
                      <ProfileImage className="h-16 w-16 p-2 mr-4"></ProfileImage>
                    )}
                  </div>
                  <div
                    key={comentario.id}
                    className="mt-2 p-4 rounded-md bg-white w-full"
                  >
                    <p className="font-semibold mb-2 text-slate-500">
                      Comentario de {comentario.comentador}:
                    </p>
                    <p className="bg-slate-100 p-2 rounded-md">
                      {comentario.comentario}
                    </p>
                    <p className="text-xs text-gray-500 text-right mt-1">
                      {dayjs(comentario.fecha_hora).format("DD/MM/YY")}
                    </p>
                  </div>
                </div>
              ))}
              {permiso.comentarios?.length < 1 && (
                <div className="italic w-full text-center mt-4 text-slate-500">
                  Sin comentarios.
                </div>
              )}
            </div>
            {!!permiso.es_procesable && (
              <div className="mt-4">
                <h3 className="font-semibold mb-2">Dejar un comentario:</h3>
                <div className="p-4 bg-slate-200 rounded-md">
                  <textarea
                    value={comentario}
                    disabled={comentario_loading}
                    onChange={(e) =>
                      dispatch(permisoActions.writeComentario(e.target.value))
                    }
                    placeholder="Escribe un comentario..."
                    className="border rounded-md p-2 w-full"
                  />
                  <button
                    disabled={comentario_loading}
                    onClick={handleAddComment}
                    className="bg-sky-500 text-white rounded-md py-2 px-4 mt-2"
                  >
                    Agregar comentario
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Right Column: Solicitante and Jefe Encargado */}
          <div className="p-4">
            <h2 className="text-lg font-semibold pb-3 border-b mb-4">
              Solicitante
            </h2>
            <div className="flex items-center mb-4">
              {permiso.solicitante_foto ? (
                <img
                  src={permiso.solicitante_foto}
                  alt={permiso.solicitante}
                  className="w-16 h-16 rounded-md mr-4"
                />
              ) : (
                <ProfileImage className="h-16 w-16 p-2 mr-4"></ProfileImage>
              )}
              <div>
                <p className="font-semibold">{permiso.solicitante}</p>
                <p>{permiso.solicitante_cargo}</p>
              </div>
            </div>

            <h2 className="text-lg font-semibold pb-2 border-b mb-4">
              Jefe Encargado
            </h2>
            <div className="flex items-center">
              {permiso.jefe_encargado_foto ? (
                <img
                  src={permiso.jefe_encargado_foto}
                  alt={permiso.jefe_encargado}
                  className="w-16 h-16 rounded-md mr-4"
                />
              ) : (
                <ProfileImage className="h-16 w-16 p-2 mr-4"></ProfileImage>
              )}
              <div>
                <p className="font-semibold">{permiso.jefe_encargado}</p>
                <p>{permiso.jefe_encargado_cargo || "Sin cargo asignado"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
