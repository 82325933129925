import React from "react";
import { Route, Routes } from "react-router-dom";
import { estructuraRootRoutes } from "src/modules/estructura/root/rootRoutes";
import { ajusteRoutes } from "src/modules/ajuste/handlers/routes";
import { competenciaRootRoutes } from "src/modules/competencia/root/rootRoutes";
// import { evaluacionRoutes } from "src/modules/evaluacion/handlers/routes";
import { kpisRoutes } from "src/modules/kpi/handlers/routes";
// import { planCarreraRoutes } from "src/modules/plancarrera/handlers/routes";
// import { remuneracionRoutes } from "src/modules/remuneracion/handlers/routes";
import { colaboradorRoutes } from "src/modules/colaborador/root/rootRoutes";
import { analisisRoutes } from "src/modules/analisis/handlers/routes";
import { evaluacionRoutes } from "src/modules/evaluacion/handlers/routes";
import { remuneracionRoutes } from "src/modules/remuneracion/handlers/routes";
import { planCarreraRoutes } from "src/modules/plancarrera/handlers/routes";
import { politicaRoutes } from "src/modules/politica/handlers/routes";
import { objetivoRoutes } from "src/modules/objetivos/handlers/routes";
import { beneficiosRoutes } from "src/modules/beneficios/handlers/routes";
import { feedbackRoutes } from "src/modules/feedback/handlers/routes";
import { aprendizajeRoutes } from "src/modules/aprendizaje/handlers/routes";
import { permisoRoutes } from "src/modules/permiso/handlers/routes";

const routes = [
  ...analisisRoutes,
  ...ajusteRoutes,
  ...competenciaRootRoutes,
  ...estructuraRootRoutes,
  ...colaboradorRoutes,
  ...kpisRoutes,
  ...objetivoRoutes,
  ...evaluacionRoutes,
  ...planCarreraRoutes,
  ...remuneracionRoutes,
  ...politicaRoutes,
  ...beneficiosRoutes,
  ...feedbackRoutes,
  ...aprendizajeRoutes,
  ...permisoRoutes,
];

const RootRoutes = () => {
  return (
    <Routes>
      {routes.map((route, idx) => (
        <Route key={idx} {...route} />
      ))}
    </Routes>
  );
};

export default RootRoutes;
