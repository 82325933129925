import React, { useEffect, useState } from "react";
import { TextIndentLeft, TextIndentRight } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "src/images/logo.svg";
import logo2 from "src/images/logo2.svg";
import { empresaActions } from "src/modules/ajuste/handlers/redux";
import { commonActions } from "src/modules/common/handler/redux";
import { USUARIOS_IDS_BLOQUEADOS_REMUNERACION } from "src/modules/remuneracion/helpers/helpers";
import {
  deleteCookie,
  deleteUserFromCookie,
  getUserFromCookie,
} from "src/utils/helpers";
import { CaretDownFill } from "react-bootstrap-icons";

function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarCollapse = useSelector((state) => state.common.sidebarCollapse);
  const empresa = useSelector((state) => state.empresa.empresa);
  const usuario = getUserFromCookie();
  const { pathname } = useLocation();

  const modulosLinks = () => {
    let modulosLinks = [
      { name: "Evaluaciones", to: "/evaluaciones" },
      {
        name: "Planes de carrera",
        to: "/plan-carrera/familias",
        planCarrera: true,
      },
      { name: "Beneficios", to: "/beneficios/", beneficios: true },
      { name: "Feedback", to: "/feedback/", feedback: true },
    ];
    if (
      !USUARIOS_IDS_BLOQUEADOS_REMUNERACION.includes(usuario.colaborador_id) &&
      !usuario.es_admin_parcial
    ) {
      modulosLinks.push({
        name: "Remuneraciones",
        to: "/remuneracion/escala",
        remuneracion: true,
      });
    }
    return modulosLinks;
  };

  // Esto se agrega porque se necesita mostrar a toyotoshi solo estos tres módulos, se debe cambiar luego
  const toyotoshiOptions = [
    { name: "Evaluaciones", to: "/evaluaciones" },
    {
      name: "Planes de carrera",
      to: "/plan-carrera/familias",
      planCarrera: true,
    },
    {
      name: "Remuneraciones",
      to: "/remuneracion/escala",
      remuneracion: true,
    },
  ];
  // Id de Toyotoshi en produccion es 49
  const modulosLinksAux = empresa.id === 49 ? toyotoshiOptions : modulosLinks();

  let sections = [
    {
      title: "General",
      links: [
        { name: "Análisis de datos", to: "/analisis" },
        {
          name: "Aprendizaje",
          to: "/aprendizaje/formacion",
          aprendizaje: true,
        },
      ],
    },
    {
      title: "Ajustes",
      links: [
        { name: "Ajustes", to: "/ajustes" },
        { name: "Estructura", to: "/estructura/cargos", estructura: true },
        { name: "Nómina", to: "/nomina" },
        {
          name: "Competencias",
          to: "/competencias/general",
          competencia: true,
        },
        { name: "KPIs", to: "/kpis/colaboradores" },
        { name: "Objetivos", to: "/objetivos/cargos", objetivos: true },
        { name: "Políticas", to: "/politica/evaluaciones", politica: true },
      ],
    },
    {
      title: "Modulos",
      links: modulosLinksAux, //modulosLinks(),
    },
    {
      title: "Gestión Administrativa",
      links: [{ name: "Permisos", to: "/permisos", permiso: true }],
    },
  ];

  const [openSections, setOpenSections] = useState(() => {
    const initialOpenSections = {};
    sections.forEach((section) => {
      initialOpenSections[section.title] = true;
    });
    return initialOpenSections;
  });
  const toggleSection = (sectionTitle) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [sectionTitle]: !prevOpenSections[sectionTitle],
    }));
  };
  const toggleHandler = () => {
    dispatch(commonActions.sidebarCollapse(!sidebarCollapse));
  };
  const getActiveClass = (isActive, link) => {
    const activeClass = "text-sky-500 font-bold";
    const inactiveClass =
      "text-gray-500 no-underline hover:font-bold hover:text-sky-500";
    //
    const esLinkCompetencia = pathname.includes("/competencias/");
    const esLinkEstructura = pathname.includes("/estructura/");
    const esLinkRemuneracion = pathname.includes("/remuneracion/");
    const esLinkPlanCarrera = pathname.includes("/plan-carrera/");
    const esLinkPolitica = pathname.includes("/politica/");
    const esLinkObjetivo = pathname.includes("/objetivos/");
    const esLinkBeneficios = pathname.includes("/beneficios/");
    const esLinkFeedback = pathname.includes("/feedback/");
    const esLinkAprendizaje = pathname.includes("/aprendizaje/");
    const esLinkPermiso = pathname.includes("/permiso");
    //
    if (esLinkCompetencia && link.competencia) {
      return activeClass;
    } else if (esLinkEstructura && link.estructura) {
      return activeClass;
    } else if (esLinkRemuneracion && link.remuneracion) {
      return activeClass;
    } else if (esLinkPlanCarrera && link.planCarrera) {
      return activeClass;
    } else if (esLinkPolitica && link.politica) {
      return activeClass;
    } else if (esLinkObjetivo && link.objetivos) {
      return activeClass;
    } else if (esLinkBeneficios && link.beneficios) {
      return activeClass;
    } else if (esLinkFeedback && link.feedback) {
      return activeClass;
    } else if (esLinkAprendizaje && link.aprendizaje) {
      return activeClass;
    } else if (esLinkPermiso && link.permiso) {
      return activeClass;
    }

    return isActive ? activeClass : inactiveClass;
  };
  const getLogo = () => {
    if (empresa.logotipo)
      return <img className="sidebar-logo" src={empresa.logotipo} alt="logo" />;
    return (
      <div className="h-full w-full bg-sky-500 rounded-md flex items-center justify-center text-white text-3xl">
        {`${empresa?.nombre?.length > 0 ? empresa?.nombre[0] : ""}`}
      </div>
    );
  };
  useEffect(() => {
    if (Object.keys(empresa).length === 0) {
      dispatch(empresaActions.empresa());
    }
  }, []);
  //
  return (
    <nav
      className={`flex flex-col pl-8 py-4 text-lg  shadow relative overflow-hidden transition-all ease-linear duration-300 ${
        !sidebarCollapse ? ` w-16` : `min-w-min`
      }`}
    >
      {/*  */}
      <div className="flex flex-row justify-between items-start relative mb-8">
        <div
          className={`flex items-center h-12 gap-2 transition-all ease-linear duration-50 ${
            !sidebarCollapse ? ` opacity-0 w-0 ` : `w-52 `
          }`}
        >
          <img src={logo2} alt="logo" />
          <span className="font-bold">Admin</span>
        </div>
        <button className="z-10 pr-4 pt-2" onClick={toggleHandler}>
          {!!sidebarCollapse && <TextIndentRight color={"#0ba3fb"} size={24} />}
          {!sidebarCollapse && <TextIndentLeft color={"#0ba3fb"} size={24} />}
        </button>
      </div>
      {/*  */}
      <div
        className={`flex flex-col gap-4 w-52 transition-all ease-linear duration-300 custom-scrollbar ${
          !sidebarCollapse ? ` opacity-0 ` : ``
        }`}
      >
        <div
          className={`flex h-16 mb-4 transition-all ease-linear duration-300`}
        >
          {!!sidebarCollapse && (
            <div className="flex items-center text-sm gap-2">
              <div className="h-16 w-16">{getLogo()}</div>
            </div>
          )}
        </div>

        {sections.map((section, index) => (
          <div
            key={`section-${index}`}
            className="transition-all ease-linear duration-300"
          >
            <div
              className="flex items-center text-gray-600 text-sm font-semibold mb-2 cursor-pointer"
              onClick={() => toggleSection(section.title)}
            >
              {section.title}
              <div className="ml-2">
                <CaretDownFill color="#6B7280" size={10} />
              </div>
            </div>
            {openSections[section.title] && (
              <ul>
                {section.links.map((link, linkIndex) => (
                  <li
                    key={`link-${index}-${linkIndex}`}
                    className="mb-2 ml-4 transition-all ease-linear duration-300"
                  >
                    <NavLink
                      {...link}
                      onClick={() => {
                        dispatch(commonActions.setTitle(link.name));
                        dispatch(commonActions.showSidebar(true));
                      }}
                      className={({ isActive }) =>
                        getActiveClass(isActive, link)
                      }
                    >
                      {link.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
        <div
          onClick={() => {
            deleteCookie("token", "/", ".wel.com.py");
            deleteCookie("token", "/"); // localhost
            deleteCookie("kento", "/", ".wel.com.py");
            deleteCookie("kento", "/"); // localhost
            deleteUserFromCookie(".wel.com.py");
            deleteUserFromCookie(); // localhost
            navigate("/auth/login/");
          }}
          className={
            "text-gray-500 no-underline hover:font-bold cursor-pointer hover:text-sky-500"
          }
        >
          Cerrar sesión
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
