import { useEffect, useState } from "react";

export const SearchInput = ({ value, onChange, delay = 300, ...props }) => {
  const [searchValue, setSearchValue] = useState(value);
  useEffect(() => {
    // Set up the debounce effect
    const handler = setTimeout(() => {
      onChange(searchValue); // Call onChange with the debounced value
    }, delay);

    // Cleanup function to clear timeout on unmount or when searchValue/delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue, delay, onChange]);

  // Update local state when the input value changes
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <input
      type="text"
      placeholder="Buscar..."
      value={searchValue}
      // onChange={(e) => onChange(e.target.value)}
      onChange={handleChange}
      className={`border rounded p-2 ${props.className}`}
    />
  );
};
