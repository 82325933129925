import React from "react";
import { APROBACION_PERMISOS } from "../helper/constants";

function RadioGroupPermisos({ value, onChange }) {
  return (
    <div className="">
      <div className="space-y-4">
        {APROBACION_PERMISOS.map((option) => (
          <div key={option.value} className="flex flex-col">
            <label className="flex items-center space-x-3">
              <input
                type="radio"
                name="aprobacion"
                value={option.value}
                checked={value === option.value}
                onChange={() => onChange(option.value)}
                className="form-radio h-4 w-4 text-sky-600 border-gray-300 focus:ring-sky-500"
              />
              <span className="font-semibold">{option.label}</span>
            </label>
            {/* Helper text específico para cada opción */}
            <p className="text-sm text-gray-500 ml-7">{option.helperText}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RadioGroupPermisos;
