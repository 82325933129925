import axios from "axios";
import { useEffect, useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  FileEarmarkArrowDownFill,
  Search,
} from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StatisticHorizontal from "src/modules/analisis/components/StatisticHorizontal";
import BarChart from "src/modules/common/components/BarChart";
import backButton from "src/modules/common/components/images/back-arrow.png";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import PercentageCircular from "src/modules/common/components/PercentageCircular";
import Pie from "src/modules/common/components/Pie";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import SearchSelect from "src/modules/common/components/SearchSelect";
import { commonActions } from "src/modules/common/handler/redux";
import useDebouncedSearch from "src/modules/common/hooks/useDebouncedValue";
import { getToken } from "src/utils/helpers";
import { evaluacionActions } from "../handlers/redux";
import useAreas from "../hooks/useAreas";
import useDepartamentos from "../hooks/useDepartamentos";
import useSucursales from "../hooks/useSucursales";
import DataTable from "src/modules/common/components/DataTable";
import { useEvaluacionData } from "../hooks/useEvaluacionData";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";

const CompetenciaCard = ({ competencia }) => {
  const { nombre, descripcion, conductas = [] } = competencia;
  //
  return (
    <div className="border rounded-md">
      <div className="bg-slate-200 p-4">{nombre}</div>
      <div className="p-4">
        <div className="font-bold mb-4">Promedio por conductas</div>
        {conductas.length > 0 && (
          <ul className="flex flex-col gap-8">
            {conductas.map((conducta, idx) => (
              <li key={`conduc-${idx}`}>
                <div className="flex justify-between">
                  <div>{conducta.descripcion}</div>
                  <div>{`${(conducta.promedio + "").replace(".", ",")}`}%</div>
                </div>
              </li>
            ))}
          </ul>
        )}
        {conductas.length < 1 && (
          <div className="flex justify-center border rounded-md p-2">
            <div>No existen conductas</div>
          </div>
        )}
      </div>
    </div>
  );
};

const SearchColaboradoresInput = ({
  onSearch,
  searchValue,
  placeholder = "Busque por nombre o cargo...",
}) => {
  const { searchValue: searchQuery, handleChange } = useDebouncedSearch(
    searchValue,
    700,
    onSearch
  );
  //
  return (
    <div className="w-1/2 flex justify-between items-center my-4 border rounded-md bg-slate-100">
      <input
        type="text"
        className="p-2 bg-slate-100 flex-1 outline-none"
        placeholder={placeholder}
        onChange={handleChange}
        value={searchQuery}
      />
      <Search className="mr-2"></Search>
    </div>
  );
};
const PuntajeColaboradorRow = ({ colaborador = {} }) => {
  const {
    nombre = "",
    apellido = "",
    cargo = {},
    foto_empleado,
    promedio = 0,
  } = colaborador;
  return (
    <div className="flex items-center p-2 rounded-md hover:bg-slate-100">
      <ProfileInfoCard
        // key={"col-" + 1}
        image={colaborador.foto_empleado}
        name={`${nombre} ${apellido}`}
      >
        <div className="text-sm">{`${cargo?.nombre || "Sin asignar"}`}</div>
      </ProfileInfoCard>
      <div>{(promedio + "").replace(".", ",")}%</div>
    </div>
  );
};
const PuntajesColaboradoresTable = ({
  colaboradores = [],
  loading = false,
  onOrder = () => {},
}) => {
  const [ordering, setOrdering] = useState("");
  const onOrderingClick = (e) => {
    e.stopPropagation();
    switch (ordering) {
      case "asc":
        setOrdering("desc");
        onOrder("desc");
        break;
      case "desc":
        setOrdering("");
        onOrder("");
        break;
      default:
        setOrdering("asc");
        onOrder("asc");
        break;
    }
  };
  return (
    <div>
      <div className="flex justify-between items-center p-2 rounded-md hover:bg-slate-100 border-b pb-1">
        <div className="font-semibold">Colaborador</div>
        <div className="flex gap-2">
          <div className="font-semibold">Puntaje</div>
          <div className="hover:cursor-pointer" onClick={onOrderingClick}>
            <ChevronUp
              color={ordering === "asc" || !ordering ? "black" : "gray"}
              size={11}
            ></ChevronUp>
            <ChevronDown
              color={ordering === "desc" || !ordering ? "black" : "gray"}
              size={11}
            ></ChevronDown>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-1 text-sm mt-2">
        {!loading && (
          <>
            {colaboradores.length > 0 &&
              colaboradores.map((colaborador, idx) => (
                <PuntajeColaboradorRow
                  colaborador={colaborador}
                  key={`pc-${idx}`}
                ></PuntajeColaboradorRow>
              ))}
            {colaboradores.length < 1 && (
              <div className="p-2 text-center">No existen colaboradores</div>
            )}
          </>
        )}
        {!!loading && (
          <div className="animate-pulse">
            {[1, 2, 3].map((l, i) => (
              <div
                key={`li-${i}`}
                className="flex items-center justify-between p-2 rounded-md"
              >
                <div className="flex gap-2">
                  <div className="w-16 h-16 bg-slate-200 rounded-md"></div>
                  <div className="flex flex-col items-center justify-center gap-4">
                    <div className="w-16 h-2 bg-slate-200 rounded-md"></div>
                    <div className="w-16 h-2 bg-slate-200 rounded-md"></div>
                  </div>
                </div>
                <div className="w-10 h-8 bg-slate-200 rounded-md"></div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
const PuntajeRow = ({
  item = {},
  isCollapsable = true,
  selectedItem,
  setSelectedItem,
  onSelectItem,
  subItems = [],
  subItemsLoading = false,
  subItemsTableConfig = {},
}) => {
  const { nombre = "", promedio = 0 } = item;
  //
  const onToggleCollapse = (e) => {
    e.stopPropagation();
    if (selectedItem?.id === item?.id) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
      !!onSelectItem && item.id && onSelectItem(item?.id);
    }
  };
  //
  return (
    <div className="">
      <div
        className={`flex items-center justify-between px-4 py-6 rounded-tl-md rounded-tr-md hover:bg-slate-100 ${
          selectedItem?.id === item?.id ? "bg-slate-100" : ""
        }`}
      >
        <div className="flex flex-col items-center justify-start mr-4">
          <div className="font-semibold">{nombre}</div>
        </div>
        <div className="flex items-center gap-1">
          <div className="">{(promedio + "").replace(".", ",")}%</div>
          {!!isCollapsable && (
            <div onClick={onToggleCollapse}>
              {selectedItem?.id === item?.id && <ChevronUp />}
              {selectedItem?.id !== item?.id && <ChevronDown />}
            </div>
          )}
        </div>
      </div>
      {/*  */}
      {selectedItem?.id === item?.id && (
        <div className="w-full flex px-4 bg-gray-100x max-h-96 border rounded-bl-md rounded-br-md overflow-auto">
          {!!subItemsTableConfig?.headers && (
            <DataTable
              containerClass={"w-full my-8x pb-2"}
              data={subItems}
              loading={subItemsLoading}
              config={subItemsTableConfig}
              // pagination
              // totalRecords={kpisNoVigentes_count}
              // onPaginate={onPaginate}
              // outerPageSize={PageSize}
              // check config
              checkable={false}
            />
          )}
        </div>
      )}
    </div>
  );
};
const PuntajesTable = ({
  items = [],
  loading = false,
  selectedItem,
  setSelectedItem,
  subItems,
  subItemsLoading = false,
  onSelectItem,
  subItemsTableConfig,
}) => {
  return (
    <div>
      <div className="flex flex-col gap-1 text-sm mt-2">
        {!loading ? (
          <>
            {items.length > 0 ? (
              items.map((item, idx) => (
                <PuntajeRow
                  item={item}
                  key={`puntaje-${idx}`}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  onSelectItem={onSelectItem}
                  subItems={subItems}
                  subItemsLoading={subItemsLoading}
                  subItemsTableConfig={subItemsTableConfig}
                />
              ))
            ) : (
              <div className="p-2 text-center">No existen elementos</div>
            )}
          </>
        ) : (
          <div className="animate-pulse">
            {[1, 2, 3].map((_, i) => (
              <div
                key={`loading-${i}`}
                className="flex items-center justify-between px-4 py-6 rounded-md"
              >
                <div className="flex gap-2">
                  {/* <div className="w-16 h-16 bg-slate-200 rounded-md"></div> */}
                  <div className="flex flex-col items-start justify-between gap-4">
                    <div className="w-24 h-2 bg-slate-200 rounded-md"></div>
                    <div className="w-16 h-2 bg-slate-200 rounded-md"></div>
                  </div>
                </div>
                <div className="w-10 h-8 bg-slate-200 rounded-md"></div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
const pieChartColors = ["red", "blue", "green", "yellow", "orange", "red"];

function EvaluacionInformePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { idEvaluacion } = params;
  const informe_evaluacion = useSelector(
    (state) => state.evaluacion.informe_evaluacion
  );
  const informe_evaluacion_loading = useSelector(
    (state) => state.evaluacion.informe_evaluacion_loading
  );
  //
  const informe_evaluacion_conductas = useSelector(
    (state) => state.evaluacion.informe_evaluacion_conductas
  );
  const informe_evaluacion_conductas_loading = useSelector(
    (state) => state.evaluacion.informe_evaluacion_conductas_loading
  );
  //
  const {
    promedio_competencias_generales = 0,
    promedio_competencias_areas = 0,
    promedio_competencias_cargos = 0,
    promedio_kpis = 0,
    promedio_general = 0,
    promedios_por_competencias_generales = [],
    porcentajes_por_clasificaciones = [],
    clasificaciones: calificaciones = [],
  } = informe_evaluacion;
  const { resumen_conductas_generales = [] } = informe_evaluacion_conductas;
  //
  const colaboradores_promedios = useSelector(
    (state) => state.evaluacion.colaboradores_promedios
  );
  const colaboradores_promedios_count = useSelector(
    (state) => state.evaluacion.colaboradores_promedios_count
  );
  const colaboradores_promedios_filter = useSelector(
    (state) => state.evaluacion.colaboradores_promedios_filter
  );
  const colaboradores_promedios_loading = useSelector(
    (state) => state.evaluacion.colaboradores_promedios_loading
  );
  // AREAS
  const areas_promedios = useSelector(
    (state) => state.evaluacion.areas_promedios
  );
  const areas_promedios_filter = useSelector(
    (state) => state.evaluacion.areas_promedios_filter
  );
  const areas_promedios_loading = useSelector(
    (state) => state.evaluacion.areas_promedios_loading
  );
  // SUCURSALES
  const sucursales_promedios = useSelector(
    (state) => state.evaluacion.sucursales_promedios
  );
  const sucursales_promedios_filter = useSelector(
    (state) => state.evaluacion.sucursales_promedios_filter
  );
  const sucursales_promedios_loading = useSelector(
    (state) => state.evaluacion.sucursales_promedios_loading
  );
  // DEPARTAMENTOS
  const departamentos_promedios = useSelector(
    (state) => state.evaluacion.departamentos_promedios
  );
  const departamentos_promedios_filter = useSelector(
    (state) => state.evaluacion.departamentos_promedios_filter
  );
  const departamentos_promedios_loading = useSelector(
    (state) => state.evaluacion.departamentos_promedios_loading
  );
  //
  const informe_sucursal_colaboradores = useSelector(
    (state) => state.evaluacion.informe_sucursal_colaboradores
  );
  const informe_sucursal_colaboradores_loading = useSelector(
    (state) => state.evaluacion.informe_sucursal_colaboradores_loading
  );
  //
  const informe_area_colaboradores = useSelector(
    (state) => state.evaluacion.informe_area_colaboradores
  );
  const informe_area_colaboradores_loading = useSelector(
    (state) => state.evaluacion.informe_area_colaboradores_loading
  );
  //
  const informe_departamento_colaboradores = useSelector(
    (state) => state.evaluacion.informe_departamento_colaboradores
  );
  const informe_departamento_colaboradores_loading = useSelector(
    (state) => state.evaluacion.informe_departamento_colaboradores_loading
  );
  //
  const useSucursalesData = () =>
    useEvaluacionData(
      evaluacionActions.getSucursalColaboradores,
      (state) => state.evaluacion.sucursales_promedios,
      (state) => state.evaluacion.sucursales_promedios_loading
    );
  const {
    data: sucursalesData,
    loading: sucursalesLoading,
    selectedItem: selectedSucursal,
    setSelectedItem: setSelectedSucursal,
    fetchData: fetchSucursalColaboradores,
  } = useSucursalesData();
  //
  const useAreasData = () =>
    useEvaluacionData(
      evaluacionActions.getAreaColaboradores,
      (state) => state.evaluacion.areas_promedios,
      (state) => state.evaluacion.areas_promedios_loading
    );
  const {
    data: areasData,
    loading: areasLoading,
    selectedItem: selectedArea,
    setSelectedItem: setSelectedArea,
    fetchData: fetchAreaColaboradores,
  } = useAreasData();
  //
  const useDepartamentosData = () =>
    useEvaluacionData(
      evaluacionActions.getDepartamentoColaboradores,
      (state) => state.evaluacion.departamentos_promedios,
      (state) => state.evaluacion.departamentos_promedios_loading
    );
  const {
    data: departamentosData,
    loading: departamentosLoading,
    selectedItem: selectedDepartamento,
    setSelectedItem: setSelectedDepartamento,
    fetchData: fetchDepartamentoColaboradores,
  } = useDepartamentosData();

  const datatableConfig = {
    headers: [
      {
        title: "Lista de KPIs",
        contentClass: "px-2 py-4",
        accessor: "nombre",
      },
      {
        title: "Métrica",
        accessor: "metrica_display",
      },
      {
        title: "Frecuencia",
        accessor: "periodo_display",
      },
    ],
    rowActions: [],
  };
  const getSucursalColaboradores = (sucursalId) => {
    dispatch(
      evaluacionActions.getSucursalColaboradores({
        id: idEvaluacion,
        sucursalId: sucursalId,
      })
    );
  };

  const getAreaColaboradores = (areaId) => {
    dispatch(
      evaluacionActions.getAreaColaboradores({
        id: idEvaluacion,
        areaId: areaId,
      })
    );
  };
  const getDepartamentoColaboradores = (departamentoId) => {
    dispatch(
      evaluacionActions.getDepartamentoColaboradores({
        id: idEvaluacion,
        departamentoId: departamentoId,
      })
    );
  };
  //
  const getResumenCompetenciasGeneralesData = () => {
    const data = promedios_por_competencias_generales.map((prom, idx) => ({
      x: idx + 1,
      y: prom.promedio,
      name: prom.nombre,
    }));
    return data;
  };
  const getDistribucacionCalificacionesData = () => {
    const data = porcentajes_por_clasificaciones
      .filter((por, idx) => por.clasificacion !== "Pendiente")
      .map((por, idx) => ({
        x: idx + 1,
        y: por.porcentaje,
        name: por.clasificacion,
        cantidad: por.cantidad_colaboradores,
      }));
    //
    return data;
  };
  const onSearchColaboradorPromedio = (value) => {
    //
    dispatch(
      evaluacionActions.setColaboradoresPromediosFilter({
        ...colaboradores_promedios_filter,
        id: idEvaluacion,
        nombre: value,
        pagination: {
          pageSize: 10,
          page: 1,
        },
      })
    );
  };
  const onChangeColaboradorPromedioPageSize = (e) => {
    const value = e.target?.value;
    dispatch(
      evaluacionActions.setColaboradoresPromediosFilter({
        ...colaboradores_promedios_filter,
        id: idEvaluacion,
        pagination: {
          pageSize: parseInt(value),
          page: 1,
        },
      })
    );
  };
  const onFilterColaboradorPromedio = (e) => {
    const value = e.target?.value;
    //
    dispatch(
      evaluacionActions.setColaboradoresPromediosFilter({
        ...colaboradores_promedios_filter,
        id: idEvaluacion,
        calificacion: value,
      })
    );
  };
  const [colaboradorPromedioPageSize, setColaboradorPromedioPageSize] =
    useState(10);
  const onPaginateColaboradorPromedio = (page, pageSize) => {
    //
    dispatch(
      evaluacionActions.setColaboradoresPromediosFilter({
        ...colaboradores_promedios_filter,
        id: idEvaluacion,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  const onSearchAreaPromedio = (value) => {
    // const value = e.target?.value;
    //
    dispatch(
      evaluacionActions.setAreasPromediosFilter({
        id: idEvaluacion,
        nombre: value,
      })
    );
  };
  const onSearchSucursalPromedio = (value) => {
    // const value = e.target?.value;
    //
    dispatch(
      evaluacionActions.setSucursalesPromediosFilter({
        id: idEvaluacion,
        nombre: value,
      })
    );
  };
  const onSearchDepartamentoPromedio = (value) => {
    // const value = e.target?.value;
    //
    dispatch(
      evaluacionActions.setDepartamentosPromediosFilter({
        id: idEvaluacion,
        nombre: value,
      })
    );
  };

  const [downloadLoading, setDownloadLoading] = useState(false);
  const downloadPDF = () => {
    const token = getToken();
    const URL_BASE =
      process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
    setDownloadLoading(true);
    axios({
      url: URL_BASE + `/evaluacion/${idEvaluacion}/exportar-informe`,
      method: "POST",
      responseType: "blob", // Indica que la respuesta será un archivo binario
      headers: {
        Authorization: `Bearer ${token}`, // Agregamos la cabecera de autorización con el token Bearer
      },
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `evaluacion-${idEvaluacion}-informe.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDownloadLoading(false);
      })
      .catch((error) => {
        console.error("Error al descargar el archivo PDF:", error);
        setDownloadLoading(false);
      });
  };
  const [informeFilter, setInformeFilter] = useState({ id: idEvaluacion });
  const [resumenCompetenciaFilter, setResumenCompetenciaFilter] = useState({
    id: idEvaluacion,
  });
  const {
    sucursales,
    nombre: nombreSucursal,
    filterSucursales,
  } = useSucursales();
  const { areas, nombre: nombreArea, filterAreas } = useAreas();
  const {
    departamentos,
    nombre: nombreDepartamento,
    filterDepartamentos,
  } = useDepartamentos();

  const setColaboradoresPromediosOrdering =
    (field) =>
    (order = "") => {
      let { ordering = "" } = colaboradores_promedios_filter;
      let orderingArray = ordering ? ordering.split(",") : [];

      // Remove any empty strings from the array
      orderingArray = orderingArray.filter(Boolean);

      let fieldIndex = orderingArray.indexOf(field);
      fieldIndex =
        fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;

      if (fieldIndex !== -1) {
        // If the field is already in the array, remove it
        orderingArray.splice(fieldIndex, 1);
      }

      if (order) {
        // Add the field with the correct order if specified
        field = order === "desc" ? `-${field}` : field;
        orderingArray.push(field);
      }

      ordering = orderingArray.join(",");
      //
      dispatch(
        evaluacionActions.setColaboradoresPromediosFilter({
          ...colaboradores_promedios_filter,
          id: idEvaluacion,
          ordering: ordering,
        })
      );
    };

  const colaboradoresPromediosDatatableConfig = {
    headers: [
      {
        title: "Colaboradores",
        contentClass: "px-2 py-8",
        accessor: "nombre",
        contentRenderer: (item) => (
          <div className="py-4">
            {item.nombre} {item.apellido}
          </div>
        ),
      },
      {
        title: "Puntaje",
        accessor: "promedio",
        onOrder: setColaboradoresPromediosOrdering("promedio"),
        contentRenderer: (item) => (
          <div className="">
            {item.promedio?.toFixed(2)?.toString().replace(".", ",")}%
          </div>
        ),
      },
    ],
    rowActions: [],
  };

  const createTableConfig = (onOrderFunction) => ({
    headers: [
      {
        title: "Colaboradores",
        contentClass: "px-2 py-8",
        accessor: "nombre",
        contentRenderer: (item) => (
          <div className="py-4">
            {item.nombre} {item.apellido}
          </div>
        ),
      },
      {
        title: "Puntaje",
        accessor: "promedio",
        onOrder: onOrderFunction("promedio"),
        contentRenderer: (item) => (
          <div className="">
            {item.promedio?.toFixed(2)?.toString().replace(".", ",")}%
          </div>
        ),
      },
    ],
    rowActions: [],
  });

  // Uso de la función generadora de configuración
  const sucursalColaboradoresDatatableConfig = createTableConfig(() => {});
  const areaColaboradoresDatatableConfig = createTableConfig(() => {});
  const departamentoColaboradoresDatatableConfig = createTableConfig(() => {});

  useEffect(() => {
    const { sucursal, area, departamento } = informeFilter;
    dispatch(evaluacionActions.filterInformeEvaluacion(informeFilter));
  }, [informeFilter]);

  useEffect(() => {
    const { sucursal, area, departamento } = resumenCompetenciaFilter;
    //
    dispatch(
      evaluacionActions.filterInformeEvaluacionConductas(
        resumenCompetenciaFilter
      )
    );
  }, [resumenCompetenciaFilter]);
  //
  useEffect(() => {
    dispatch(commonActions.setTitle(``));
    dispatch(commonActions.showSidebar(false));
  }, []);
  //
  return (
    <>
      {/* HEADER */}
      <div className="flex items-center bg-gray-100 py-2.5 px-7 -mx-8 border-b">
        <div
          onClick={() => navigate("/evaluaciones")}
          className={"h-full  flex items-center pr-2 -mr-2 z-10 cursor-pointer"}
        >
          <img className="back" src={backButton} alt="Regresar" />
        </div>
        <div className="font-medium px-4 text-xl">
          Informe de la evaluación:
        </div>
        <div className="flex items-center button-group"></div>
      </div>
      {/* BODY */}

      <div className="mt-8 grid grid-cols-1 md:grid-cols-12 place-items-stretch justify-center border-2border-red-400x">
        {/*  */}
        <div className="col-span-1 md:col-start-2 md:col-span-10 borderx">
          {/* PROMEDIO DE DESEMPEÑO */}
          <>
            <div className="w-full flex items-center justify-between">
              <div className="font-bold mb-6">Promedio de desempeño</div>
              {!downloadLoading && (
                <button
                  onClick={downloadPDF}
                  className="bg-sky-500 rounded-md text-white p-2 flex items-center gap-2"
                >
                  <FileEarmarkArrowDownFill size={18} />
                  <span className="font-semibold">Descargar informe</span>
                </button>
              )}
              {!!downloadLoading && (
                <button className="font-semibold bg-sky-500 rounded-md text-white p-2 animate-pulse">
                  Generando informe...
                </button>
              )}
            </div>
            <div className="flex gap-4 my-8">
              <SearchSelect
                data={sucursales}
                defaultText={"Sucursales: Todos"}
                searchValue={nombreSucursal}
                filterFunction={filterSucursales}
                onSelectItem={(item) => {
                  setInformeFilter({
                    id: idEvaluacion,
                    ...informeFilter,
                    sucursal: item,
                  });
                }}
              />
              <SearchSelect
                data={areas}
                defaultText={"Áreas: Todas"}
                searchValue={nombreArea}
                filterFunction={filterAreas}
                onSelectItem={(item) => {
                  setInformeFilter({
                    id: idEvaluacion,
                    ...informeFilter,
                    area: item,
                  });
                }}
              />
              <SearchSelect
                data={departamentos}
                defaultText={"Departamentos: Todos"}
                searchValue={nombreDepartamento}
                filterFunction={filterDepartamentos}
                onSelectItem={(item) => {
                  setInformeFilter({
                    id: idEvaluacion,
                    ...informeFilter,
                    departamento: item,
                  });
                }}
              />
            </div>
            {!informe_evaluacion_loading && (
              <div className="grid grid-cols-1 md:grid-cols-3">
                {/*  */}
                <div className="col-span-1 flex flex-col gap-2 justify-center items-center">
                  <div className="border rounded-md p-4 flex flex-col gap-2">
                    <div className="font-bold text-sm">
                      Competencias Generales
                    </div>
                    <PercentageCircular
                      width={180}
                      height={180}
                      strokeWidth={9}
                      strokeColor={"blue"}
                      value={promedio_competencias_generales.puntaje || 0}
                      content={
                        <>
                          <div className="font-bold text-2xl">
                            {promedio_competencias_generales.puntaje || 0}%
                          </div>
                          <div className="text-sm">{`${promedio_competencias_generales.clasificacion}`}</div>
                        </>
                      }
                    ></PercentageCircular>
                  </div>
                  <div className="border rounded-md p-4 flex flex-col gap-2">
                    <div className="font-bold text-sm">Competencias Áreas</div>
                    <PercentageCircular
                      width={180}
                      height={180}
                      strokeWidth={9}
                      strokeColor={"blue"}
                      value={promedio_competencias_areas.puntaje || 0}
                      content={
                        <>
                          <div className="font-bold text-2xl">
                            {promedio_competencias_areas.puntaje || 0}%
                          </div>
                          <div className="text-sm">{`${promedio_competencias_areas.clasificacion}`}</div>
                        </>
                      }
                    ></PercentageCircular>
                  </div>
                </div>
                {/*  */}
                <div className="col-span-1 flex flex-col gap-2 justify-center items-center">
                  <div className="border rounded-md p-4 flex flex-col gap-2">
                    <div className="font-bold text-sm">Competencias Cargos</div>
                    <PercentageCircular
                      width={180}
                      height={180}
                      strokeWidth={9}
                      strokeColor={"blue"}
                      value={promedio_competencias_cargos.puntaje || 0}
                      content={
                        <>
                          <div className="font-bold text-2xl">
                            {promedio_competencias_cargos.puntaje || 0}%
                          </div>
                          <div className="text-sm">{`${promedio_competencias_cargos.clasificacion}`}</div>
                        </>
                      }
                    ></PercentageCircular>
                  </div>
                  <div className="border rounded-md p-4 flex flex-col gap-2">
                    <div className="font-bold text-sm">KPIs</div>
                    <PercentageCircular
                      width={180}
                      height={180}
                      strokeWidth={9}
                      strokeColor={"blue"}
                      value={promedio_kpis.puntaje || 0}
                      content={
                        <>
                          <div className="font-bold text-2xl">
                            {promedio_kpis.puntaje || 0}%
                          </div>
                          <div className="text-sm">{`${promedio_kpis.clasificacion}`}</div>
                        </>
                      }
                    ></PercentageCircular>
                  </div>
                </div>
                {/*  */}
                <div className="col-span-1 flex flex-col gap-2 justify-center items-center">
                  <div className="border rounded-md p-4 flex flex-col gap-2">
                    <div className="font-bold text-sm">
                      Promedio de desempeño
                    </div>
                    <PercentageCircular
                      width={222}
                      height={222}
                      strokeWidth={9}
                      strokeColor={"blue"}
                      value={promedio_general.puntaje || 0}
                      content={
                        <>
                          <div className="font-bold text-2xl">
                            {promedio_general.puntaje || 0}%
                          </div>
                          <div className="text-sm">{`${promedio_general.clasificacion}`}</div>
                        </>
                      }
                    ></PercentageCircular>
                  </div>
                </div>
              </div>
            )}
            {!!informe_evaluacion_loading && (
              <div className="flex justify-center items-center p-2">
                <LoadingSpinButton
                  className="w-full md:w-1/2 justify-center"
                  message="Cargando informe..."
                ></LoadingSpinButton>
              </div>
            )}
          </>
          {/* RESUMEN GENERAL DE LA EVALUACIÓN */}
          <>
            <div className="col-span-1">
              <div className="font-bold my-8">
                Resumen general de evaluación
              </div>
              <div className="grid grid-cols-1 md:grid-cols-12 gap-1 md:gap-5">
                {/* Resumen de Competencias Generales */}
                <div className="col-span-1 md:col-span-7 flex">
                  <div className="border rounded-md p-4 flex flex-col flex-1">
                    <div className="font-bold">
                      Resumen de competencias generales
                    </div>
                    <div className="flex flex-1 items-center justify-center">
                      <BarChart
                        width={600}
                        domainPadding={{ x: 40, y: [0, 0] }}
                        data={getResumenCompetenciasGeneralesData()}
                        usingXLabels={true}
                      ></BarChart>
                    </div>
                  </div>
                </div>
                {/* Distribución de calificaciones */}
                <div className="col-span-1 md:col-span-5 flex">
                  <div className="border rounded-md p-4 flex flex-col flex-1">
                    <div className="font-bold borderx">
                      Distribución de calificaciones
                    </div>
                    {/*  */}
                    <div className="flex flex-col justify-center items-centerx flex-1 border-2x">
                      <div className="flex items-center borderx h-1/2">
                        <Pie
                          colorScale={pieChartColors}
                          width={200}
                          height={200}
                          data={getDistribucacionCalificacionesData()}
                        ></Pie>
                      </div>
                      <div className="flex flex-col gap-4 justify-aroundx text-sm">
                        {getDistribucacionCalificacionesData().map(
                          (item, idx) => {
                            return (
                              <StatisticHorizontal
                                key={`stat-${idx}`}
                                title={`${item.name}`}
                                amount={item.cantidad}
                                percentage={Math.round(item.y)}
                                titleDotColor={pieChartColors[idx]}
                              ></StatisticHorizontal>
                            );
                          }
                        )}
                      </div>
                    </div>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </>
          {/* PUNTAJES POR COLABORADORES */}
          <>
            <div className="border rounded-md p-4 mt-6">
              <div className="font-bold">Puntajes por colaboradores</div>
              <div className="flex items-center justify-between">
                <div className="flex gap-2 w-full">
                  <SearchColaboradoresInput
                    onSearch={onSearchColaboradorPromedio}
                    searchValue={colaboradores_promedios_filter?.nombre}
                  ></SearchColaboradoresInput>
                  <MostrarPaginas
                    defaultOption={10}
                    onChangePageSize={onChangeColaboradorPromedioPageSize}
                  />
                </div>
                {calificaciones.length > 0 && (
                  <select
                    onChange={onFilterColaboradorPromedio}
                    value={colaboradores_promedios_filter?.calificacion}
                    className="bg-white p-2 border border-1 border-gray-200 rounded-md"
                    placeholder="Seleccione una calificación"
                  >
                    <option value="">Selecciona una clasificación</option>
                    {calificaciones.map((calif, califIdx) => (
                      <option value={calif.id}>{calif.nombre}</option>
                    ))}
                  </select>
                )}
              </div>
              {/*  */}
              <DataTable
                containerClass={"my-8x"}
                data={colaboradores_promedios}
                loading={colaboradores_promedios_loading}
                config={colaboradoresPromediosDatatableConfig}
                // pagination
                totalRecords={colaboradores_promedios_count}
                // onPaginate={onPaginateColaboradorPromedio}
                outerPageSize={colaboradorPromedioPageSize}
                // check config
                checkable={false}
              />
            </div>
          </>
          {/* PUNTAJES POR SUCURSALES */}
          <>
            <div className="border rounded-md p-4 mt-6">
              <div className="font-bold">Puntajes por sucursales</div>
              <SearchColaboradoresInput
                placeholder="Buscar por nombre..."
                onSearch={onSearchSucursalPromedio}
                searchValue={sucursales_promedios_filter?.nombre}
              ></SearchColaboradoresInput>
              {/*  */}
              <PuntajesTable
                items={sucursalesData}
                loading={sucursalesLoading}
                selectedItem={selectedSucursal}
                setSelectedItem={setSelectedSucursal}
                subItems={informe_sucursal_colaboradores}
                subItemsLoading={informe_sucursal_colaboradores_loading}
                onSelectItem={getSucursalColaboradores}
                subItemsTableConfig={sucursalColaboradoresDatatableConfig}
              />
            </div>
          </>
          {/* PUNTAJES POR AREAS */}
          <>
            <div className="border rounded-md p-4 mt-6">
              <div className="font-bold">Promedios por áreas</div>
              <SearchColaboradoresInput
                placeholder="Buscar por nombre..."
                onSearch={onSearchAreaPromedio}
                searchValue={areas_promedios_filter?.nombre}
              ></SearchColaboradoresInput>
              {/*  */}
              <PuntajesTable
                items={areasData}
                loading={areasLoading}
                selectedItem={selectedArea}
                setSelectedItem={setSelectedArea}
                subItems={informe_area_colaboradores}
                subItemsLoading={informe_area_colaboradores_loading}
                onSelectItem={getAreaColaboradores}
                subItemsTableConfig={areaColaboradoresDatatableConfig}
              />
            </div>
          </>
          {/* PUNTAJES POR DEPARTAMENTOS */}
          <>
            <div className="border rounded-md p-4 mt-6">
              <div className="font-bold">Promedios por departamentos</div>
              <SearchColaboradoresInput
                placeholder="Buscar por nombre..."
                onSearch={onSearchDepartamentoPromedio}
                searchValue={departamentos_promedios_filter?.nombre}
              ></SearchColaboradoresInput>
              {/*  */}
              <PuntajesTable
                items={departamentosData}
                loading={departamentosLoading}
                selectedItem={selectedDepartamento}
                setSelectedItem={setSelectedDepartamento}
                subItems={informe_departamento_colaboradores}
                subItemsLoading={informe_departamento_colaboradores_loading}
                onSelectItem={getDepartamentoColaboradores}
                subItemsTableConfig={departamentoColaboradoresDatatableConfig}
              />
            </div>
          </>
          {/* RESUMEN POR CONDUCTAS */}
          {!informe_evaluacion_conductas_loading && (
            <>
              <div className="my-4">
                <div className="font-bold mb-4">Resumen por conductas</div>
                <div className="flex gap-4 my-8">
                  <SearchSelect
                    data={sucursales}
                    defaultText={"Sucursales: Todos"}
                    searchValue={nombreSucursal}
                    filterFunction={filterSucursales}
                    onSelectItem={(item) => {
                      setResumenCompetenciaFilter({
                        id: idEvaluacion,
                        ...resumenCompetenciaFilter,
                        sucursal: item,
                      });
                    }}
                  />
                  <SearchSelect
                    data={areas}
                    defaultText={"Áreas: Todas"}
                    searchValue={nombreArea}
                    filterFunction={filterAreas}
                    onSelectItem={(item) => {
                      setResumenCompetenciaFilter({
                        id: idEvaluacion,
                        ...resumenCompetenciaFilter,
                        area: item,
                      });
                    }}
                  />
                  <SearchSelect
                    data={departamentos}
                    defaultText={"Departamentos: Todos"}
                    searchValue={nombreDepartamento}
                    filterFunction={filterDepartamentos}
                    onSelectItem={(item) => {
                      setResumenCompetenciaFilter({
                        id: idEvaluacion,
                        ...resumenCompetenciaFilter,
                        departamento: item,
                      });
                    }}
                  />
                </div>
                <div className="flex flex-col gap-4">
                  {resumen_conductas_generales.length > 0 &&
                    resumen_conductas_generales.map((competencia, idx) => (
                      <CompetenciaCard
                        key={`cc-${idx}`}
                        competencia={competencia}
                      ></CompetenciaCard>
                    ))}
                  {resumen_conductas_generales.length < 1 && (
                    <div className="flex justify-center w-full border rounded-md p-2">
                      <div className="">No existen competencias</div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default EvaluacionInformePage;
