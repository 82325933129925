import axios from "axios";

const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const permisoApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    getPermisos: async ({ ...filterObj }) => {
      const filter = filterObj.filter;
      const { pagination = { page: 1, pageSize: 10 } } = filterObj;
      const {
        search = "",
        // pagination = { page: 1, pageSize: 50 },
        ordering = "",
      } = filter;
      const { page, pageSize } = pagination;
      try {
        const response = await axios.get(
          `${URL_BASE}/permiso/?search=${search}&page=${page}&page_size=${pageSize}&ordering=${ordering}`,
          { headers }
        );
        return response.data;
      } catch (error) {
        console.error("getPermisos error", error);
        throw error;
      }
    },

    getPermiso: async (id) => {
      try {
        const response = await axios.get(`${URL_BASE}/permiso/${id}/`, {
          headers,
        });
        return response.data;
      } catch (error) {
        console.error("getPermiso detail error", error);
        throw error;
      }
    },

    deletePermiso: async (id) => {
      try {
        const response = await axios.delete(
          `${URL_BASE}/permiso/${id}/delete/`,
          { headers }
        );
        return response.data;
      } catch (error) {
        console.error("delete permiso error", error);
        throw error;
      }
    },

    addComentarPermiso: async (permisoId, comentario) => {
      try {
        const response = await axios.post(
          `${URL_BASE}/permiso/${permisoId}/comentarios/`,
          { comentario },
          { headers }
        );
        return response.data;
      } catch (error) {
        console.error("Error adding comentario:", error);
        throw error;
      }
    },

    deleteComentarioPermiso: async (permisoId, comentarioId) => {
      try {
        const response = await axios.delete(
          `${URL_BASE}/permiso/${permisoId}/comentarios/${comentarioId}/`,
          { headers }
        );
        return response.data;
      } catch (error) {
        console.error("delete permiso error", error);
        throw error;
      }
    },

    getSolicitudesPendientes: async (filterObj) => {
      const filter = filterObj.filter;
      const {
        pagination = { page: 1, pageSize: 10 },
        sucursal,
        area,
        departamento,
      } = filterObj;
      const {
        search = "",
        // pagination = { page: 1, pageSize: 50 },
        ordering = "",
      } = filter;
      const { page, pageSize } = pagination;
      let requestURL = `${URL_BASE}/permiso/solicitudes_pendientes/?search=${search}&page=${page}&page_size=${pageSize}&ordering=${ordering}`;
      if (sucursal) {
        requestURL = requestURL + `&sucursal=${sucursal}`;
      }
      if (area) {
        requestURL = requestURL + `&area=${area}`;
      }
      if (departamento) {
        requestURL = requestURL + `&departamento=${departamento}`;
      }
      //
      try {
        const response = await axios.get(requestURL, { headers });
        return response.data;
      } catch (error) {
        console.error("getSolicitudesPendientes error", error);
        throw error;
      }
    },

    getSolicitudesHistorial: async (filterObj) => {
      const filter = filterObj.filter ?? {};
      const {
        pagination = { page: 1, pageSize: 10 },
        sucursal,
        area,
        departamento,
      } = filterObj;
      const {
        search = "",
        // pagination = { page: 1, pageSize: 50 },
        ordering = "",
      } = filter;
      const { page, pageSize } = pagination;
      let requestURL = `${URL_BASE}/permiso/solicitudes_historial/?search=${search}&page=${page}&page_size=${pageSize}&ordering=${ordering}`;
      if (sucursal) {
        requestURL = requestURL + `&sucursal=${sucursal}`;
      }
      if (area) {
        requestURL = requestURL + `&area=${area}`;
      }
      if (departamento) {
        requestURL = requestURL + `&departamento=${departamento}`;
      }
      //
      try {
        const response = await axios.get(requestURL, { headers });
        return response.data;
      } catch (error) {
        console.error("getSolicitudesHistorial error", error);
        throw error;
      }
    },

    aceptarPermiso: async (id) => {
      try {
        const response = await axios.post(
          `${URL_BASE}/permiso/${id}/aceptar/`,
          {},
          { headers }
        );
        return response.data;
      } catch (error) {
        console.error("aceptarPermiso error", error);
        throw error;
      }
    },

    rechazarPermiso: async (id) => {
      try {
        const response = await axios.post(
          `${URL_BASE}/permiso/${id}/rechazar/`,
          {},
          { headers }
        );
        return response.data;
      } catch (error) {
        console.error("rechazarPermiso error", error);
        throw error;
      }
    },

    getTiposPermiso: async () => {
      try {
        const response = await axios.get(`${URL_BASE}/permiso/tipo-permisos/`, {
          headers,
        });
        return response.data;
      } catch (error) {
        console.error("getTiposPermiso error", error);
        throw error;
      }
    },
  };
};
