export const initialHorario = [
  {
    dia: 0,
    cantidad_horas: 0,
  },
  {
    dia: 1,
    cantidad_horas: 0,
  },
  {
    dia: 2,
    cantidad_horas: 0,
  },
  {
    dia: 3,
    cantidad_horas: 0,
  },
  {
    dia: 4,
    cantidad_horas: 0,
  },
  {
    dia: 5,
    cantidad_horas: 0,
  },
  {
    dia: 6,
    cantidad_horas: 0,
  },
];

export const dias_label = {
  0: "Lunes",
  1: "Martes",
  2: "Miercoles",
  3: "Jueves",
  4: "Viernes",
  5: "Sabado",
  6: "Domingo",
};
