export const Header2 = ({
  titulo = "",
  subtitulo = "",
  LeftComponent = null,
  RightComponent = null,
  children = null,
  className = null,
}) => {
  return (
    <div className={`flex flex-row items-center py-4 px-12x ${className}`}>
      {!!LeftComponent && <LeftComponent></LeftComponent>}
      <div className="flex flex-row flex-grow">
        <div className="flex flex-col">
          {!!titulo && <div className="text-lg font-bold">{titulo}</div>}
          {!!subtitulo && (
            <div className="text-sm text-zinc-500">{subtitulo}</div>
          )}
        </div>
        {children}
      </div>
      {!!RightComponent && <RightComponent></RightComponent>}
    </div>
  );
};
