import React from "react";
import { Paperclip } from "react-bootstrap-icons";

function ArchivoLinks({ archivos }) {
  return (
    <div className="archivo-links flex">
      {archivos.map((archivo) => (
        <a
          key={archivo.id}
          href={archivo.archivo}
          target="_blank"
          rel="noopener noreferrer"
          className="link-button flex items-center space-x-1 text-blue-600 hover:text-blue-800"
        >
          <Paperclip size={24} />
          <span>{archivo.archivo.split("/").slice(-1) || "Sin nombre"}</span>
        </a>
      ))}
    </div>
  );
}

export default ArchivoLinks;
