import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ObjectToString, getToken } from "src/utils/helpers";
import { objetivosApi } from "./api";
import { objetivoActions } from "./redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { push } from "@lagunovsky/redux-react-router";

function* colaboradoresSaga({ payload }) {
  const token = getToken();
  try {
    const colaboradores_filter = yield select(
      (state) => state.objetivo.colaboradores_filter
    );

    const data = yield call(
      objetivosApi(token).colaboradoresList,
      colaboradores_filter
    );
    !!process.env.REACT_APP_DEBUG &&
      console.info("colaboradores_filter sagas data - objetivos", data);
    yield put(objetivoActions.setColaboradores(data.results));
    yield put(objetivoActions.setColaboradoresCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  } finally {
    yield put(objetivoActions.setObjetivosComplete());
  }
}

function* colaboradorObjetivosSaga({ payload }) {
  const token = getToken();
  const colaboradorObjetivos_filter = yield select(
    (state) => state.objetivo.colaboradorObjetivos_filter
  );
  try {
    const data = yield call(objetivosApi(token).colaboradorObjetivosList, {
      ...payload,
      colaboradorObjetivos_filter: colaboradorObjetivos_filter,
    });

    yield put(
      objetivoActions.setColaboradorObjetivos({
        objetivos: data,
      })
    );
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* colaboradorObjetivosNoVigentesSaga({ payload }) {
  const token = getToken();
  const colaboradorKpis_filter = yield select(
    (state) => state.objetivo.colaboradorObjetivosNoVigentes_filter
  );
  try {
    const data = yield call(
      objetivosApi(token).colaboradorObjetivosNoVigentesList,
      {
        ...payload,
        colaboradorKpis_filter: colaboradorKpis_filter,
      }
    );
    yield put(objetivoActions.setColaboradorObjetivosNoVigentes(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* colaboradorObjetivosUpdateSaga({ payload }) {
  const token = getToken();
  const { id, objetivos } = payload;
  // const params = { id: id, objetivos: objetivos };
  //
  try {
    const data = yield call(
      objetivosApi(token).colaboradorObjetivosUpdate,
      payload
    );
    //
    yield put(objetivoActions.getColaboradorObjetivos({ id: id }));
    yield put(objetivoActions.getColaboradorObjetivosNoVigentes({ id: id }));
    SuccessToast({ message: "Objetivos actualizados con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    yield put(objetivoActions.colaboradorUpdateObjetivosComplete());
    ErrorToast({ message: message });
  }
}

function* objetivoCargoColaboradorHistorialSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      objetivosApi(token).objetivoCargoColaboradorHistorial,
      payload
    );
    yield put(objetivoActions.setObjetivoCargoHistorial(data));
  } catch (error) {
    yield put(objetivoActions.setObjetivoCargoHistorial({}));
    const errores = error.response?.data || {};
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* asignarObjetivosCargoMasivoSaga({ payload }) {
  const token = getToken();
  const colaboradores_checked = yield select(
    (state) => state.objetivo.colaboradores_checked
  );
  const colaboradores = colaboradores_checked.map((colaborador, cidx) => ({
    id: colaborador.id,
  }));
  const { objetivos } = payload;
  //
  try {
    const data = yield call(objetivosApi(token).asignarObjetivosCargoMasivo, {
      colaboradores,
      objetivos,
    });
    yield put(objetivoActions.getColaboradores());
    yield put(objetivoActions.asignarObjetivosCargoMasivoComplete());
    yield put(push("/objetivos/cargos"));
    SuccessToast({ message: "Objetivos por cargo asignados con exito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    yield put(objetivoActions.asignarObjetivosCargoMasivoComplete());
    ErrorToast({ message: message });
  }
}

function* metricasSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(objetivosApi(token).metricaslist);
    yield put(objetivoActions.setMetricas({ metricas: data }));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* periodosSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(objetivosApi(token).periodoslist);
    yield put(objetivoActions.setPeriodos({ periodos: data }));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* importarObjetivosPorColaboradorSaga({ payload }) {
  const token = getToken();
  const fileToUpload = yield select(
    (state) => state.objetivo.fileToUploadPorColaborador
  );
  try {
    const data = yield call(
      objetivosApi(token).importarObjetivosPorColaborador,
      {
        fileToUpload,
      }
    );
    SuccessToast({ message: "Objetivos por cargo importados con éxito!" });
    yield put(objetivoActions.getColaboradores());
    yield put(objetivoActions.setErroresImportacion([]));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG &&
      console.log("Error al importar objetivos por cargo.", error);
    const errores = error.response.data.errores;
    yield put(objetivoActions.setErroresImportacion(errores));
    ErrorToast({ message: "Hubieron errores en la importación." });
  } finally {
    yield put(objetivoActions.setFileToUploadPorColaboradorFinal());
  }
}

function* importarObjetivosPorCargoSaga({ payload }) {
  const token = getToken();
  const fileToUpload = yield select(
    (state) => state.objetivo.fileToUploadPorCargo
  );
  try {
    const data = yield call(objetivosApi(token).importarObjetivosPorCargo, {
      fileToUpload,
    });
    SuccessToast({ message: "Objetivos por cargo importados con éxito!" });
    yield put(objetivoActions.getColaboradores());
    yield put(objetivoActions.setErroresImportacion([]));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG &&
      console.log("Error al importar objetivos por cargo", error);
    const errores = error.response.data.errores;
    yield put(objetivoActions.setErroresImportacion(errores));
    ErrorToast({ message: "Hubieron errores en la importación." });
  } finally {
    yield put(objetivoActions.setFileToUploadPorCargoFinal());
  }
}

export function* objetivosSagas() {
  yield takeEvery(objetivoActions.getColaboradores.type, colaboradoresSaga);
  yield takeLatest(
    objetivoActions.setColaboradoresFilter.type,
    colaboradoresSaga
  );
  yield takeEvery(
    objetivoActions.getColaboradorObjetivos.type,
    colaboradorObjetivosSaga
  );
  yield takeLatest(
    objetivoActions.setColaboradorObjetivosFilter.type,
    colaboradorObjetivosSaga
  );
  // NO VIGENTES
  yield takeEvery(
    objetivoActions.getColaboradorObjetivosNoVigentes.type,
    colaboradorObjetivosNoVigentesSaga
  );
  yield takeLatest(
    objetivoActions.setColaboradorObjetivosNoVigentesFilter.type,
    colaboradorObjetivosNoVigentesSaga
  );
  //
  yield takeEvery(
    objetivoActions.colaboradorUpdateObjetivos.type,
    colaboradorObjetivosUpdateSaga
  );
  yield takeEvery(
    objetivoActions.asignarObjetivosCargoMasivo.type,
    asignarObjetivosCargoMasivoSaga
  );
  yield takeEvery(objetivoActions.getMetricas.type, metricasSaga);
  yield takeEvery(objetivoActions.getPeriodos.type, periodosSaga);

  yield takeEvery(
    objetivoActions.setFileToUploadPorColaborador.type,
    importarObjetivosPorColaboradorSaga
  );
  yield takeEvery(
    objetivoActions.setFileToUploadPorCargo.type,
    importarObjetivosPorCargoSaga
  );

  yield takeEvery(
    objetivoActions.getObjetivoCargoHistorial.type,
    objetivoCargoColaboradorHistorialSaga
  );
}
