import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable from "src/modules/common/components/DataTable";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import SearchSelect from "src/modules/common/components/SearchSelect";
import { commonActions } from "src/modules/common/handler/redux";
import useAreas from "src/modules/evaluacion/hooks/useAreas";
import useDepartamentos from "src/modules/evaluacion/hooks/useDepartamentos";
import useSucursales from "src/modules/evaluacion/hooks/useSucursales";
import { SearchInput } from "../components/SearchInput";
import { permisoActions } from "../handlers/redux";
import { getStatusColors } from "../helpers/constants";

export function PermisoSolicitudes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const {
    solicitudes_pendientes,
    solicitudes_pendientes_loading,
    solicitudes_historial_count,
    solicitudes_pendientes_filter,
    pendientes_count,
  } = useSelector((state) => state.permiso);

  const {
    sucursales,
    nombre: nombreSucursal,
    filterSucursales,
  } = useSucursales();
  const { areas, nombre: nombreArea, filterAreas } = useAreas();
  const {
    departamentos,
    nombre: nombreDepartamento,
    filterDepartamentos,
  } = useDepartamentos();

  useEffect(() => {
    dispatch(commonActions.setTitle("Permisos"));
    dispatch(
      commonActions.setTitleMenu([
        {
          name: "Solicitudes",
          to: "/permisos",
          additionalTitle: pendientes_count,
        },
        {
          name: "Historial",
          to: "/permisos/historial",
        },
      ])
    );
    return () => {
      commonActions.setTitleMenu([]);
    };
  }, [pendientes_count]);

  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);

  // Fetch pending requests on component mount and whenever the search term changes
  useEffect(() => {
    dispatch(
      permisoActions.getSolicitudesPendientes({
        ...solicitudes_pendientes_filter,
        filter: { search: searchTerm },
        pagination: {
          page: 1,
          pageSize: PageSize,
        },
      })
    );
  }, [dispatch, searchTerm]);

  const rechazarHandler = (permisoId) => (event) => {
    event.stopPropagation();
    const confirm = window.confirm("Está seguro de RECHAZAR la solicitud?");
    if (confirm) {
      dispatch(permisoActions.rechazarPermiso(permisoId));
    }
  };

  const aceptarHandler = (permisoId) => (event) => {
    event.stopPropagation();
    const confirm = window.confirm("Está seguro de ACEPTAR la solicitud?");
    if (confirm) {
      dispatch(permisoActions.aceptarPermiso(permisoId));
    }
  };

  const setOrdering =
    (field) =>
    (order = "") => {
      let { ordering = "" } = solicitudes_pendientes_filter;
      let orderingArray = ordering.split(",") || [];
      let emptyIndex = orderingArray.indexOf("");
      if (emptyIndex) {
        orderingArray.splice(emptyIndex, 1);
      }
      let fieldIndex = orderingArray.indexOf(field);
      fieldIndex =
        fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
      // si ya esta el campo, vemos si se modifica o elimina
      if (fieldIndex > 0) {
        orderingArray.splice(fieldIndex, 1);
        if (order) {
          field = order === "desc" ? `-${field}` : field;
          orderingArray[fieldIndex] = field;
        }
      } else {
        // si no está, lo agregamos
        field = order === "desc" ? `-${field}` : field;
        orderingArray.push(field);
      }
      ordering = orderingArray.join(",");
      //
      dispatch(
        permisoActions.getSolicitudesPendientes({
          ...solicitudes_pendientes_filter,
          ordering: ordering,
        })
      );
    };

  const datatableConfigPermisosPendientes = {
    headers: [
      {
        title: "Colaboradores",
        contentClass: "font-bold",
        // onOrder: setOrderingPermisos("empleado"),
        accessor: "solicitante",
        contentRenderer: (item) => {
          const { solicitante, cargo, solicitante_foto } = item;
          return (
            <div className="flex items-center gap-3">
              <div>
                {!solicitante_foto && (
                  <ProfileImage className="h-28 w-28 p-3"></ProfileImage>
                )}
                {!!solicitante_foto && (
                  <div className="w-16 bg-slate-200 rounded-md">
                    <img
                      className="object-cover h-16 w-16 rounded-md"
                      src={solicitante_foto}
                      alt={`Foto ${solicitante}`}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <div className="font-semibold">{solicitante}</div>
                <div>Cargo: {cargo ? cargo : "Sin cargo"}</div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Solicitud",
        accessor: "inicio",
        // onOrder: setOrderingPermisos("tipo_permiso"),
        contentRenderer: (item) => {
          const { inicio, estado, tipo, asunto } = item;
          const [day, month] = dayjs(inicio).format("D MMM").split(" ");
          return (
            <div className="flex items-center gap-4 w-full md:w-1/2">
              <div
                style={{
                  backgroundColor: getStatusColors(estado).background,
                }}
                className="text-lg font-semibold text-center py-2 px-4 rounded-md"
              >
                <div>{day}</div>
                <div>{month}</div>
              </div>
              <div>
                <div className="font-semibold">{tipo}</div>
                <div>Asunto: {asunto}</div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Tiempo",
        accessor: "dias",
        // onOrder: setOrderingPermisos("estado"),
        contentRenderer: (item) => (
          <div>
            {item.dias} {item.dias > 1 ? "días" : "día"}
          </div>
        ),
      },
      {
        title: "Acción",
        accessor: "estado",
        // onOrder: setOrderingPermisos("estado"),
        contentRenderer: (item) => {
          return (
            <div className="flex gap-2">
              <button
                onClick={rechazarHandler(item.id)}
                className="border border-black rounded-md p-2"
              >
                Rechazar
              </button>
              <button
                onClick={aceptarHandler(item.id)}
                className="bg-sky-500 p-2 text-white rounded-md"
              >
                Aceptar
              </button>
            </div>
          );
        },
      },
    ],
    rowActions: [
      // {
      //   label: "Aprobar",
      //   action: (item) => {
      //     dispatch(permisoActions.aceptarPermiso(item.id));
      //   },
      // },
      // {
      //   label: "Rechazar",
      //   action: (item) => {
      //     dispatch(permisoActions.rechazarPermiso(item.id));
      //   },
      // },
    ],
  };

  const onPaginatePermisos = (page, pageSize) => {
    dispatch(
      permisoActions.getSolicitudesPendientes({
        ...solicitudes_pendientes_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const goToPermisoDetalle = (permiso) => {
    navigate(`/permisos/detalle/${permiso.id}`);
  };

  return (
    <div>
      <div className="flex flex-col gap-4 px-12 py-4">
        <div className="flex justify-between">
          <h1 className="font-semibold text-lg">Solicitudes Pendientes</h1>
        </div>

        <div>
          <div className="w-full flex items-center mb-4 gap-4">
            <SearchInput
              value={searchTerm}
              onChange={setSearchTerm}
              className=" w-full md:w-1/2"
            />
            <MostrarPaginas
              containerClass={"w-1/2x"}
              defaultOption={10}
              onChangePageSize={(e) => setPageSize(e.target.value)}
            />
          </div>
          <div className="flex gap-4 my-8">
            <SearchSelect
              data={sucursales}
              defaultText={"Sucursales: Todos"}
              searchValue={nombreSucursal}
              filterFunction={filterSucursales}
              onSelectItem={(item) => {
                dispatch(
                  permisoActions.getSolicitudesPendientes({
                    ...solicitudes_pendientes_filter,
                    sucursal: item,
                  })
                );
              }}
            />
            <SearchSelect
              data={areas}
              defaultText={"Áreas: Todas"}
              searchValue={nombreArea}
              filterFunction={filterAreas}
              onSelectItem={(item) => {
                dispatch(
                  permisoActions.getSolicitudesPendientes({
                    ...solicitudes_pendientes_filter,
                    area: item,
                  })
                );
              }}
            />
            <SearchSelect
              data={departamentos}
              defaultText={"Departamentos: Todos"}
              searchValue={nombreDepartamento}
              filterFunction={filterDepartamentos}
              onSelectItem={(item) => {
                dispatch(
                  permisoActions.getSolicitudesPendientes({
                    ...solicitudes_pendientes_filter,
                    departamento: item,
                  })
                );
              }}
            />
          </div>
          {solicitudes_pendientes_loading ? (
            <LoadingSpinButton message="Cargando..." />
          ) : (
            <div className="px-4">
              <DataTable
                containerClass={"my-8"}
                data={solicitudes_pendientes}
                loading={solicitudes_pendientes_loading}
                config={datatableConfigPermisosPendientes}
                // Pagination
                totalRecords={solicitudes_historial_count}
                onPaginate={onPaginatePermisos}
                outerPageSize={PageSize}
                outerPage={Page}
                outerSetPage={setPage}
                // Check functionality (left as false since you mentioned you don’t need it now)
                checkable={false}
                onRowClick={goToPermisoDetalle}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PermisoSolicitudes;
