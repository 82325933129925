import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { commonActions } from "src/modules/common/handler/redux";

export function AjusteLayout({ children }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(commonActions.setTitle("Ajustes"));
    dispatch(
      commonActions.setTitleMenu([
        {
          name: "Generales",
          to: "/ajustes",
        },
        {
          name: "Laborales",
          to: "/ajustes/laborales",
        },
      ])
    );
    dispatch(commonActions.showSidebar(true));
  }, []);
  return <div>{children}</div>;
}
