export const APROBACION_PERMISOS = [
  {
    value: "Jefe Directo",
    label: "Jefe Directo",
    helperText:
      "En esta configuración, todos los permisos solicitados por los colaboradores deben ser aprobados únicamente por el Jefe Directo. ",
  },
  {
    value: "Admin",
    label: "Admin",
    helperText:
      "En esta configuración, todos los permisos solicitados por los colaboradores deben ser aprobados únicamente por el Admin de la plataforma. ",
  },
  {
    value: "Jefe Directo + Admin",
    label: "Jefe Directo + Admin",
    helperText:
      "Tanto el Jefe Directo como el Admin deben aprobar las solicitudes de permisos.",
  },
];
