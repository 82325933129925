import { push } from "@lagunovsky/redux-react-router";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { commonActions } from "src/modules/common/handler/redux";
import { getToken, ObjectToString } from "src/utils/helpers";
import { kpiApi } from "./api";
import { kpiActions } from "./redux";

function* asignarKpisMasivoSaga({ payload }) {
  const token = getToken();
  const colaboradores_checked = yield select(
    (state) => state.kpi.colaboradores_checked
  );
  const colaboradores = colaboradores_checked.map((colaborador, cidx) => ({
    id: colaborador.id,
  }));
  const { kpis } = payload;
  //
  try {
    const data = yield call(kpiApi(token).asignarKpisMasivo, {
      colaboradores,
      kpis,
    });
    yield put(kpiActions.colaboradores());
    yield put(kpiActions.asignarKpisMasivoComplete());
    yield put(push("/kpis/colaboradores"));
    SuccessToast({ message: "KPIs asignados con exito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    yield put(kpiActions.asignarKpisMasivoComplete());
    ErrorToast({ message: message });
  }
}

function* kpiDeleteSaga({ payload }) {
  const token = getToken();
  const kpi = payload;
  const params = { ...kpi };
  !!process.env.REACT_APP_DEBUG && console.log({ payload });
  try {
    const data = yield call(kpiApi(token).delete, params);
    yield put(kpiActions.kpis());
    yield put(kpiActions.kpiDeleteSuccess());
    yield put(kpiActions.resetCheckKpis());
    if (params.fromDetail) {
      yield put(push("/kpis/"));
    }
    SuccessToast({ message: "KPI eliminado con éxito!" });
  } catch (error) {
    yield put(kpiActions.kpiDeleteSuccess());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* colaboradorKpiDeleteSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(kpiApi(token).colaboradorKpiDelete, payload);
    yield put(kpiActions.colaboradorKpis({ id: payload.colaboradorId }));
    yield put(kpiActions.colaboradorKpiDeleteSuccess());
    yield put(kpiActions.resetCheckColaboradorKpis());
    SuccessToast({ message: "KPI eliminado con éxito!" });
  } catch (error) {
    yield put(kpiActions.colaboradorKpiDeleteSuccess());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
    !!process.env.REACT_APP_DEBUG && console.log({ error });
  }
}

function* kpiColaboradoresDeleteSaga({ payload }) {
  const token = getToken();
  const kpi = yield select((state) => state.kpi.kpi);
  const params = { kpiId: kpi.id, colaboradoresId: payload };
  try {
    !!process.env.REACT_APP_DEBUG &&
      console.info("kpiColaboradoresDeleteSaga", payload, kpi);
    const data = yield call(kpiApi(token).kpiColaboradoresDelete, params);
    //
    yield put(kpiActions.resetCheckKpiColaboradores());
    yield put(kpiActions.resetKpiColaboradoresDelete());
    yield put(kpiActions.kpiColaboradores(kpi));
    SuccessToast({ message: "Colaboradores desvinculados con éxito!" });
  } catch (error) {
    // yield put(kpiActions.colaboradorKpiDeleteSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* colaboradorKpisDeleteSaga({ payload }) {
  const token = getToken();
  const colaborador = yield select((state) => state.colaborador.colaborador);
  const params = { colaboradorId: colaborador.id, kpisIds: payload };
  try {
    const data = yield call(kpiApi(token).colaboradorKpisDelete, params);
    //
    yield put(kpiActions.resetCheckColaboradorKpis());
    yield put(kpiActions.resetColaboradorKpisDelete());
    yield put(kpiActions.colaboradorKpis(colaborador));
    SuccessToast({ message: "KPIs eliminados con éxito!" });
  } catch (error) {
    // yield put(kpiActions.colaboradorKpiDeleteSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* kpiColaboradorDeleteSaga({ payload }) {
  const token = getToken();
  try {
    !!process.env.REACT_APP_DEBUG &&
      console.info("kpiColaboradorDeleteSaga", payload);
    const data = yield call(kpiApi(token).kpiColaboradorDelete, payload);
    // TODO revisar si no debe ser kpiId no colaboradorId
    yield put(kpiActions.kpiColaboradores({ id: payload.colaboradorId }));
    yield put(kpiActions.kpiColaboradorDeleteSuccess());
    SuccessToast({ message: "Colaborador desvinculado con éxito!" });
  } catch (error) {
    yield put(kpiActions.kpiColaboradorDeleteSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

//
function* kpisDeleteSaga({ payload }) {
  const token = getToken();
  try {
    !!process.env.REACT_APP_DEBUG && console.info("kpisDeleteSaga", payload);
    const data = yield call(kpiApi(token).kpisDelete, { kpisIds: payload });
    yield put(kpiActions.resetCheckKpis());
    yield put(kpiActions.kpis());
    SuccessToast({ message: "KPIs eliminados con éxito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
//
function* kpiUpdateSaga({ payload }) {
  const token = getToken();
  const kpi = yield select((state) => state.kpi.kpi);
  const fromModal = payload?.fromModal || false;
  const params = { ...kpi };
  try {
    const data = yield call(kpiApi(token).update, params);
    yield put(kpiActions.kpiUpdateSuccess());
    //
    if (fromModal) {
      yield put(push(`/kpis/detalle/${params.id}`));
      yield put(kpiActions.kpiColaboradores(params));
      yield put(commonActions.setSelectModal({ open: false }));
      yield put(kpiActions.resetCheckColaboradores());
      return;
    }
    yield put(push(`/kpis/`));
    yield put(kpiActions.kpis());
    SuccessToast({ message: "KPI editado con éxito!" });
  } catch (error) {
    yield put(kpiActions.kpiUpdateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* kpiCreateSaga({ payload }) {
  const token = getToken();
  const kpi = yield select((state) => state.kpi.kpi);
  const colaborador = yield select((state) => state.colaborador.colaborador);
  const colaboradorId = colaborador.id;
  const params = { ...kpi };
  try {
    const data = yield call(kpiApi(token).create, params);
    const newKPI = data.data;
    //
    if (colaboradorId) {
      yield put(
        kpiActions.setColaboradoresIds({
          kpiId: newKPI.id,
          colaboradoresIds: [colaboradorId],
        })
      );
      yield put(kpiActions.kpiCreateSuccess());
      // yield put(kpiActions.colaboradorKpis({ id: colaboradorId }));
      yield put(push(`/kpis/colaboradores/${colaboradorId}`));
      SuccessToast({ message: "KPI creado con éxito!" });
      return;
    }
    //
    yield put(kpiActions.kpiCreateSuccess());
    yield put(push("/kpis/colaboradores"));
    yield put(kpiActions.kpis());
    SuccessToast({ message: "KPI creado con éxito!" });
  } catch (error) {
    yield put(kpiActions.kpiCreateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* kpiCreateSagaOld({ payload }) {
  const token = getToken();
  const kpi = yield select((state) => state.kpi.kpi);
  const colaboradorId = payload?.colaboradorId || false;
  const params = { ...kpi };
  try {
    const data = yield call(kpiApi(token).create, params);
    const newKPI = data.data;
    //
    if (colaboradorId) {
      yield put(
        kpiActions.setColaboradoresIds({
          kpiId: newKPI.id,
          colaboradoresIds: [colaboradorId],
        })
      );
      yield put(kpiActions.kpiCreateSuccess());
      yield put(kpiActions.colaboradorKpis({ id: colaboradorId }));
      yield put(push(`/kpis/colaboradores/${colaboradorId}`));
      return;
    }
    //
    yield put(kpiActions.kpiCreateSuccess());
    yield put(push("/kpis/"));
    yield put(kpiActions.kpis());
    SuccessToast({ message: "KPI creado con éxito!" });
  } catch (error) {
    yield put(kpiActions.kpiCreateSuccess());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* kpiGetSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(kpiApi(token).get, payload);
    yield put(kpiActions.kpiSet(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* kpisSaga({ payload }) {
  const token = getToken();
  const kpis_filter = yield select((state) => state.kpi.kpis_filter);
  try {
    const data = yield call(kpiApi(token).list, { kpis_filter });
    yield put(kpiActions.setKpis({ kpis: data }));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* colaboradoresSaga({ payload }) {
  const token = getToken();
  try {
    const colaboradores_filter = yield select(
      (state) => state.kpi.colaboradores_filter
    );

    const data = yield call(
      kpiApi(token).colaboradoresList,
      colaboradores_filter
    );
    yield put(kpiActions.setColaboradores({ colaboradores: data }));
    yield put(kpiActions.setColaboradoresCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* kpiColaboradoresSaga({ payload }) {
  const token = getToken();
  const kpi = yield select((state) => state.kpi.kpi);
  const kpiColaboradores_filter = yield select(
    (state) => state.kpi.kpiColaboradores_filter
  );
  const params = { ...payload, ...kpiColaboradores_filter };
  try {
    const data = yield call(kpiApi(token).kpiColaboradoresList, params);
    yield put(
      kpiActions.setKpiColaboradores({ colaboradores: data?.results || [] })
    );
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* colaboradorKpisSaga({ payload }) {
  const token = getToken();
  const colaboradorKpis_filter = yield select(
    (state) => state.kpi.colaboradorKpis_filter
  );
  try {
    const data = yield call(kpiApi(token).colaboradorKpisList, {
      ...payload,
      colaboradorKpis_filter: colaboradorKpis_filter,
    });
    yield put(
      kpiActions.setColaboradorKpis({
        kpis: data,
      })
    );
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* colaboradorKpisNoVigentesSaga({ payload }) {
  const token = getToken();
  const colaboradorKpis_filter = yield select(
    (state) => state.kpi.colaboradorKpisNoVigentes_filter
  );
  try {
    const data = yield call(kpiApi(token).colaboradorKpisNoVigentesList, {
      ...payload,
      colaboradorKpis_filter: colaboradorKpis_filter,
    });
    yield put(kpiActions.setColaboradorKpisNoVigentes(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
// ===
function* kpiColaboradorDetailSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(kpiApi(token).kpiColaboradorDetail, payload);
    yield put(kpiActions.setKpiColaborador(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* kpiColaboradorHistorialSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(kpiApi(token).kpiColaboradorHistorial, payload);
    yield put(kpiActions.setKpiHistorial(data));
  } catch (error) {
    yield put(kpiActions.setKpiHistorial({}));
    const errores = error.response?.data || {};
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* kpiColaboradorUpdateSaga({ payload }) {
  const token = getToken();
  const kpiColaborador = yield select((state) => state.kpi.kpi_colaborador);
  const colaborador = yield select((state) => state.colaborador.colaborador);
  const params = { ...kpiColaborador };
  try {
    const data = yield call(kpiApi(token).kpiColaboradorUpdate, params);
    yield put(kpiActions.kpiColaboradorUpdateComplete());
    //
    yield put(push(`/kpis/colaboradores/${colaborador.id}`));
    // yield put(kpiActions.kpis());
    SuccessToast({ message: "KPI editado con éxito!" });
  } catch (error) {
    yield put(kpiActions.kpiColaboradorUpdateComplete());
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}
// ===

function* kpiAddColaboradoresSaga({ payload }) {
  !!process.env.REACT_APP_DEBUG && console.info("kpiAddColaboradoresSaga");
  const token = getToken();
  try {
    const data = yield call(kpiApi(token).kpiAddColaboradores, payload);
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* asignarKpisColaboradoresSaga({ payload }) {
  const token = getToken();
  const params = {
    kpisIds: payload.kpis_ids,
    colaboradoresIds: payload.colaboradores_ids,
  };
  try {
    const data = yield call(kpiApi(token).asignarKpisColaboradores, params);
    yield put(commonActions.setSelectModal({ open: false }));
    yield put(kpiActions.kpis());
    yield put(kpiActions.resetCheckKpis());
    yield put(kpiActions.resetCheckColaboradores());
    SuccessToast({ message: "KPIs asignados con éxito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(commonActions.setSelectModal({ open: false }));
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* metricasSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(kpiApi(token).metricaslist);
    yield put(kpiActions.setMetricas({ metricas: data }));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* periodosSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(kpiApi(token).periodoslist);
    yield put(kpiActions.setPeriodos({ periodos: data }));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: message });
  }
}

function* colaboradorKpisUpdateSaga({ payload }) {
  const token = getToken();
  const { id, kpis, archivados = [] } = payload;
  const params = { id: id, kpis: kpis, archivados: archivados };
  //
  try {
    const data = yield call(kpiApi(token).colaboradorKpisUpdate, params);
    //
    yield put(kpiActions.colaboradorKpis({ id: id }));
    yield put(kpiActions.getColaboradorKpisNoVigentes({ id: id }));
    SuccessToast({ message: "KPIs actualizados con éxito!" });
  } catch (error) {
    const errores = error.response.data;
    const message = ObjectToString(errores);
    yield put(kpiActions.colaboradorUpdateKpisComplete());
    ErrorToast({ message: message });
  }
}

function* importarKpisPorColaboradorSaga({ payload }) {
  const token = getToken();
  const fileToUpload = yield select(
    (state) => state.kpi.fileToUploadPorColaborador
  );
  try {
    const data = yield call(kpiApi(token).importarKpisPorColaborador, {
      fileToUpload,
    });
    SuccessToast({ message: "KPIs importados con éxito!" });
    yield put(kpiActions.colaboradores());
    yield put(kpiActions.setErroresImportacion([]));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG &&
      console.log("Error al importar kpis", error);
    const errores = error.response.data.errores;
    yield put(kpiActions.setErroresImportacion(errores));
    ErrorToast({ message: "Hubieron errores en la importación." });
  } finally {
    yield put(kpiActions.setFileToUploadPorColaboradorFinal());
  }
}

function* importarKpisPorCargoSaga({ payload }) {
  const token = getToken();
  const fileToUpload = yield select((state) => state.kpi.fileToUploadPorCargo);
  try {
    const data = yield call(kpiApi(token).importarKpisPorCargo, {
      fileToUpload,
    });
    SuccessToast({ message: "KPIs importados con éxito!" });
    yield put(kpiActions.colaboradores());
    yield put(kpiActions.setErroresImportacion([]));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG &&
      console.log("Error al importar kpis", error);
    const errores = error.response.data.errores;
    yield put(kpiActions.setErroresImportacion(errores));
    ErrorToast({ message: "Hubieron errores en la importación." });
  } finally {
    yield put(kpiActions.setFileToUploadPorCargoFinal());
  }
}

export function* kpiSagas() {
  yield takeEvery(kpiActions.asignarKpisMasivo, asignarKpisMasivoSaga);
  //
  yield takeEvery(
    kpiActions.colaboradorUpdateKpis.type,
    colaboradorKpisUpdateSaga
  );
  // create
  yield takeEvery(kpiActions.kpiCreate.type, kpiCreateSaga);
  // update
  yield takeEvery(kpiActions.kpiUpdate.type, kpiUpdateSaga);
  yield takeEvery(
    kpiActions.kpiColaboradorUpdate.type,
    kpiColaboradorUpdateSaga
  );
  // delete
  yield takeEvery(kpiActions.kpiDelete.type, kpiDeleteSaga);
  yield takeEvery(
    kpiActions.colaboradorKpiDelete.type,
    colaboradorKpiDeleteSaga
  );
  yield takeEvery(
    kpiActions.kpiColaboradorDelete.type,
    kpiColaboradorDeleteSaga
  );
  yield takeEvery(
    kpiActions.setKpiColaboradoresDelete.type,
    kpiColaboradoresDeleteSaga
  );
  yield takeEvery(
    kpiActions.setColaboradorKpisDelete.type,
    colaboradorKpisDeleteSaga
  );
  yield takeEvery(kpiActions.setKpisDelete.type, kpisDeleteSaga);
  //
  yield takeEvery(kpiActions.setColaboradoresIds.type, kpiAddColaboradoresSaga);
  yield takeEvery(
    kpiActions.setAsignarKpisColaboradores.type,
    asignarKpisColaboradoresSaga
  );
  // get
  yield takeEvery(kpiActions.kpiGet.type, kpiGetSaga);
  yield takeEvery(kpiActions.getKpiColaborador.type, kpiColaboradorDetailSaga);
  yield takeEvery(kpiActions.getKpiHistorial.type, kpiColaboradorHistorialSaga);
  // list
  yield takeEvery(kpiActions.kpis.type, kpisSaga);
  yield takeLatest(kpiActions.setKpisFilter.type, kpisSaga);
  yield takeEvery(kpiActions.colaboradores.type, colaboradoresSaga);
  yield takeLatest(kpiActions.setColaboradoresFilter.type, colaboradoresSaga);
  yield takeEvery(kpiActions.kpiColaboradores.type, kpiColaboradoresSaga);
  yield takeLatest(
    kpiActions.setKpiColaboradoresFilter.type,
    kpiColaboradoresSaga
  );
  yield takeEvery(kpiActions.colaboradorKpis.type, colaboradorKpisSaga);
  yield takeLatest(
    kpiActions.setColaboradorKpisFilter.type,
    colaboradorKpisSaga
  );
  // NO VIGENTES
  yield takeEvery(
    kpiActions.getColaboradorKpisNoVigentes.type,
    colaboradorKpisNoVigentesSaga
  );
  yield takeLatest(
    kpiActions.setColaboradorKpisNoVigentesFilter.type,
    colaboradorKpisNoVigentesSaga
  );
  //
  yield takeEvery(kpiActions.metricas.type, metricasSaga);
  yield takeEvery(kpiActions.periodos.type, periodosSaga);
  //
  yield takeEvery(
    kpiActions.setFileToUploadPorColaborador.type,
    importarKpisPorColaboradorSaga
  );
  yield takeEvery(
    kpiActions.setFileToUploadPorCargo.type,
    importarKpisPorCargoSaga
  );
}
