import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const empresaApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    update: async ({ id, nombre, rubro }) => {
      try {
        let params = { nombre, rubro };
        if (isNaN(rubro)) {
          params = { nombre, id: (rubro = { rubro }) };
        }
        const data = await axios.put(URL_BASE + `/empresa/${id}/`, params, {
          headers,
        });
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    get: async () => {
      try {
        const empresas = await axios.get(URL_BASE + "/empresa/", { headers });
        return empresas.data;
      } catch (error) {
        throw error;
      }
    },
    rubrolist: async () => {
      try {
        const rubros = await axios.get(URL_BASE + "/rubro/", { headers });
        return rubros.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("rubros error", error);
        throw error;
      }
    },
    logoUpdate: async ({ id, logotipo }) => {
      try {
        const headersForm = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
        !!process.env.REACT_APP_DEBUG &&
          console.info("logoUpdate api", logotipo, typeof logotipo);
        const response = await axios.put(
          URL_BASE + `/empresa/${id}/logo`,
          { logotipo: logotipo },
          { headers: headersForm }
        );
        !!process.env.REACT_APP_DEBUG && console.info("response", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("nomina error", error);
        throw error;
      }
    },
    // /api/admin/empresa/habilitar-edicion-kpis/ put method
    // Inside the empresaApi function, add the following code to implement the /api/admin/empresa/habilitar-edicion-kpis/ put method:

    habilitarEdicionKpis: async (habilitar) => {
      // AUTO EDICION DE KPIS*
      try {
        const response = await axios.put(
          URL_BASE + "/empresa/habilitar-edicion-kpis/",
          { habilitacion_edicion_propia_kpis: habilitar },
          { headers }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("habilitarEdicionKpis error", error);
        throw error;
      }
    },
    limitarVisibilidadPlanCarrera: async (limitar) => {
      try {
        const response = await axios.put(
          URL_BASE + "/empresa/limitar-visibilidad-plan-carrera/",
          { limitar_visibilidad_plan_carrera: limitar },
          { headers }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("habilitarEdicionKpis error", error);
        throw error;
      }
    },
    deshabilitarEdicionKpis: async (deshabilitar_edicion_kpis) => {
      try {
        const response = await axios.put(
          URL_BASE + "/empresa/deshabilitar-edicion-kpis/",
          { deshabilitar_edicion_kpis: deshabilitar_edicion_kpis },
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    habilitarSuperarPuntajeEvaluacion: async (
      habilitar_puntaje_evaluacion_mayor_100
    ) => {
      try {
        const response = await axios.put(
          URL_BASE + "/empresa/habilitar-superar-puntaje-evaluacion/",
          {
            habilitar_puntaje_evaluacion_mayor_100:
              habilitar_puntaje_evaluacion_mayor_100,
          },
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    cambiarPermisos: async (params) => {
      try {
        const response = await axios.post(
          URL_BASE + "/empresa/cambiar-permisos/",
          { ...params },
          { headers }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    //
    empresaLaboral: async () => {
      const response = await axios.get(URL_BASE + `/empresa/laboral/`, {
        headers,
      });
      return response.data;
    },
    createHorasPorDia: async (params) => {
      const response = await axios.post(
        URL_BASE + "/empresa/dias-habiles/create",
        params,
        { headers }
      );
      return response.data;
    },
    //
    salarioMinimoUpdate: async (params) => {
      const response = await axios.put(
        URL_BASE + "/empresa/salario-minimo/update",
        params,
        { headers }
      );
      return response.data;
    },
    updateAprobacionPermisos: async (params) => {
      const response = await axios.put(
        URL_BASE + "/empresa/aprobacion-permisos/",
        params,
        { headers }
      );
      return response.data;
    },
  };
};
