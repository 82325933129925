import { createRouterReducer } from "@lagunovsky/redux-react-router";
import { empresaReducer } from "src/modules/ajuste/handlers/redux";
import { authReducer } from "src/modules/auth/handlers/redux";
import { commonReducer } from "src/modules/common/handler/redux";
import { kpiReducer } from "../modules/kpi/handlers/redux";

import { estructuraRootReducer } from "src/modules/estructura/root/rootReducer";
import { competenciaRootReducer } from "src/modules/competencia/root/rootReducer";
import { colaboradorRootReducer } from "src/modules/colaborador/root/rootReducer";
import { analisisReducer } from "src/modules/analisis/handlers/redux";
import { evaluacionReducer } from "src/modules/evaluacion/handlers/redux";
import { remuneracionReducer } from "src/modules/remuneracion/handlers/redux";
import { planCarreraReducer } from "src/modules/plancarrera/handlers/redux";
import { politicaReducer } from "src/modules/politica/handlers/redux";
import { objetivoReducer } from "src/modules/objetivos/handlers/redux";
import { beneficioReducer } from "src/modules/beneficios/handlers/redux";
import { feedbackReducer } from "src/modules/feedback/handlers/redux";
import { aprendizajeReducer } from "src/modules/aprendizaje/handlers/redux";
import { permisoReducer } from "src/modules/permiso/handlers/redux";

export const rootReducer = (history) => ({
  router: createRouterReducer(history),
  ...estructuraRootReducer,
  ...competenciaRootReducer,
  ...colaboradorRootReducer,
  analisis: analisisReducer,
  empresa: empresaReducer,
  auth: authReducer,
  common: commonReducer,
  kpi: kpiReducer,
  evaluacion: evaluacionReducer,
  remuneracion: remuneracionReducer,
  plan_carrera: planCarreraReducer,
  politica: politicaReducer,
  objetivo: objetivoReducer,
  beneficio: beneficioReducer,
  feedback: feedbackReducer,
  aprendizaje: aprendizajeReducer,
  permiso: permisoReducer,
});
