const STATUS_COLORS = {
  pendiente: { background: "#FFA8001A", text: "#FFA800" },
  aceptado: { background: "#90ED7D4D", text: "#1EB000" },
  rechazado: { background: "#DF3A3333", text: "#DF3A33" },
};

/**
 * Function to get the background and text color for a given status.
 *
 * @param {string} estado - The status key ('pendiente', 'aceptado', or 'rechazado')
 * @returns {object} - An object containing the background and text color.
 *
 * @example
 * const { background, text } = getStatusColors('pendiente');
 */
export const getStatusColors = (estado) => {
  return STATUS_COLORS[estado] || { background: "#FFFFFF", text: "#000000" }; // Default colors if not found
};
