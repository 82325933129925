import React from "react";
import Ajuste from "../pages/Ajuste";
import { AjusteLaboral } from "../pages/AjusteLaboral";

export const ajusteRoutes = [
  {
    path: "/ajustes",
    element: <Ajuste />,
  },
  {
    path: "/ajustes/laborales",
    element: <AjusteLaboral />,
  },
];
