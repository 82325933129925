import React from "react";
import { PermisoDetail } from "../pages/PermisoDetail";
import { PermisoHistorial } from "../pages/PermisoHistorial";
import { PermisoSolicitudes } from "../pages/PermisoSolicitudes";

export const permisoRoutes = [
  {
    path: "/permisos",
    element: <PermisoSolicitudes />,
  },
  {
    path: "/permisos/historial",
    element: <PermisoHistorial />,
  },
  {
    path: "/permisos/detalle/:id",
    element: <PermisoDetail />,
  },
];
