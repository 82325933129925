import { all } from "redux-saga/effects";
import { authSagas } from "src/modules/auth/handlers/sagas";
import { empresaSagas } from "src/modules/ajuste/handlers/sagas";
import { estructuraRootSagas } from "src/modules/estructura/root/rootSagas";
import { kpiSagas } from "src/modules/kpi/handlers/sagas";
import { competenciaRootSagas } from "src/modules/competencia/root/rootSagas";
import { colaboradorRootSagas } from "src/modules/colaborador/root/rootSagas";
import { analisisSagas } from "src/modules/analisis/handlers/sagas";
import { evaluacionSagas } from "src/modules/evaluacion/handlers/sagas";
import { remuneracionSagas } from "src/modules/remuneracion/handlers/sagas";
import { planCarreraSagas } from "src/modules/plancarrera/handlers/sagas";
import { politicaSagas } from "src/modules/politica/handlers/sagas";
import { objetivosSagas } from "src/modules/objetivos/handlers/sagas";
import { beneficioSagas } from "src/modules/beneficios/handlers/sagas";
import { feedbackSagas } from "src/modules/feedback/handlers/sagas";
import { aprendizajeSagas } from "src/modules/aprendizaje/handlers/sagas";
import { permisoSagas } from "src/modules/permiso/handlers/sagas";

export function* rootSagas() {
  yield all([
    authSagas(),
    analisisSagas(),
    colaboradorRootSagas(),
    competenciaRootSagas(),
    empresaSagas(),
    estructuraRootSagas(),
    kpiSagas(),
    evaluacionSagas(),
    remuneracionSagas(),
    planCarreraSagas(),
    politicaSagas(),
    objetivosSagas(),
    beneficioSagas(),
    feedbackSagas(),
    aprendizajeSagas(),
    permisoSagas(),
  ]);
}
